import { createContext, useContext } from "react"
import { Member } from "../api/models/member"


export type MemberContent = {
    member: Member | undefined,
    updateContactInfo: (phone: string, email: string) => void,
    refreshMember: (memberId: string) => void
}

export const MemberContext = createContext<MemberContent>({
    member: undefined,
    updateContactInfo: (phone: string, email: string) => { },
    refreshMember: (memberId: string) => { },
})

export const useMemberContext = () => useContext(MemberContext)