import axios from 'axios';
import { CardDataCommand, CardMemberData, CardNotificationCommand } from './models/cardNotice';

export async function sendCardNoticeApi(bearerToken: string, orgSeasonId : string,  sendCardNoticeCommand: CardNotificationCommand): Promise<void> {
    const baseUrl = process.env.REACT_APP_API_URL;
    const endpoint = `/api/v1/card-data/orgSeason/${orgSeasonId}/sendNotice`;
    await axios.post(baseUrl + endpoint, sendCardNoticeCommand, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function getCardDataMemberApi(bearerToken: string, orgSeasonId : string, secCode : string): Promise<CardMemberData> {
    const baseUrl = process.env.REACT_APP_API_URL;
    const endpoint = `/api/v1/card-data/orgSeason/${orgSeasonId}/${secCode}`;
    const result = await axios.get(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
    return result.data as CardMemberData;
}

export async function updateCardDataMemberApi(bearerToken: string, orgSeasonId : string, secCode: string,  cardDataCommand: CardDataCommand): Promise<void> {
    const baseUrl = process.env.REACT_APP_API_URL;
    const endpoint = `/api/v1/card-data/orgSeason/${orgSeasonId}/${secCode}`;
    await axios.post(baseUrl + endpoint, cardDataCommand, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}


