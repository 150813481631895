import React from 'react';
import { Button, Input, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { convertBlobToBase64Url } from '../utils/binaryHelper';

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

interface UploadImageProps {
  id: string,
  accept?: string,
  maxWidth?: number,
  maxHeight?: number,
  aspectRatio?: number,
  onChange: (selectedFile: string) => void
}

export function UploadImageButton(props: UploadImageProps) {

  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [image, setImage] = React.useState<string>('');
  const [pageState, setPageState] = React.useState<string>('none');
  const [aspectRatio, setAspectRatio] = React.useState<number>(props.aspectRatio ?? 1);

  const [cropData, setCropData] = React.useState('#');
  const [cropper, setCropper] = React.useState<any>();


  async function handleClose() {
    setOpenDialog(false);
  }

  async function updateFile() {
    const cropData = cropper.getCroppedCanvas().toDataURL();
    setCropData(cropData);

    setPageState('imageCropped');

  }

  async function saveFile() {

    if (cropData) {
      props.onChange(cropData);
    }

    setOpenDialog(false);
  }

  async function returnToEdit() {
    setPageState('imageSelected');
  }

  const onFileSelected = async ({ target }: any) => {
    try {
      const thisFile = target.files[0] as File;
      let fileAsString = await convertBlobToBase64Url(thisFile) as string;
      setImage(fileAsString);
      setPageState('imageSelected');
      setOpenDialog(true);
      target.value = null; //this will reset the input to allow it to trigger again
    }
    catch (error: any) {
      console.log(error);
    }

  }

  function isState(state: string): boolean {
    return state === pageState;
  }

  return (
    <>
      <Grid container>
        <Grid item xs={3}>
          <label htmlFor={props.id}>
            <Input id={props.id} sx={{ display: 'none' }} inputProps={{ accept: props.accept }} type="file" onChange={onFileSelected} />
            <Button color="secondary" variant="contained" component="span">
              Update Image
            </Button>
          </label>
        </Grid>
      </Grid>

      <Dialog open={openDialog} sx={{ width: 800 }}  >
        <DialogTitle>Edit Image</DialogTitle>
        <DialogContent>
          {isState('imageSelected') && <Cropper
            src={image}
            style={{ height: 250, width: 250 }}
            // Cropper.js options
            aspectRatio={aspectRatio}
            guides={true}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false}
            onInitialized={(instance) => {
              setCropper(instance);
            }}

          />}

          {isState('imageCropped') && <img alt='cropImage' src={cropData} style={{ width: 250 }} />}

        </DialogContent>
        <DialogActions>
          {isState('imageCropped') && <Button onClick={returnToEdit}>Undo</Button>}
          {isState('imageSelected') && <Button onClick={updateFile}>Update</Button>}
          {isState('imageCropped') && <Button onClick={saveFile}>Save</Button>}
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );

}