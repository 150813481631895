import React, { useEffect, useState } from "react";
import { Grid, Box, CircularProgress } from '@mui/material';
import { BasicCard } from "../../shared/basicCard";
import { useApi } from "../../hooks/useApi";
import { BasicErrorLabel } from "../../shared/basicErrorLabel";
import { addTeamApi, deleteTeamApi } from "../../api/orgSeasonApi";
import { BasicTextBox } from "../../shared/basicTextBox";
import { MiniGrid, RowItem } from "../../shared/miniGrid";
import { Team } from "../../api/models/team";
import { BasicDropDown } from "../../shared/basicDropDown";
import { DropDownItem } from "../../models/dropDownItemViewModel";
import { useOrgSeasonContext } from "../../context/orgSeasonContext";
import { BasicButton } from "../../shared/basicButton";

export function ManageTeamsPanel() {

    const [teamRows, setTeamRows] = useState<RowItem[] | undefined>(undefined);
    const [divisionList, setDivisionList] = useState<DropDownItem[]>([]);
    const [newTeamName, setNewTeamName] = useState<string>('');
    const [newTeamDivision, setNewTeamDivision] = useState<string>('');
    const [newTeamNameError, setNewTeamNameError] = useState<string>('');

    const updateRequest = useApi(addTeamApi);
    const deleteRequest = useApi(deleteTeamApi);
    const { orgSeason, refreshOrgSeason } = useOrgSeasonContext();

    useEffect(() => {
        if (orgSeason) {
            mapTeamsToRows(orgSeason.teams);
            intDropDown(orgSeason.divisions);
        }

    }, [orgSeason]);


    async function mapTeamsToRows(responseTeams: Team[]) {
        if (responseTeams && responseTeams.length > 0) {
            const tempRows = responseTeams.map((team) => ({
                id: team.id,
                items: [{ name: 'name', value: team.name }, { name: 'division', value: team.division }]
            }));

            setTeamRows(tempRows);
        }
    }

    function intDropDown(divisions: string[]) {

        const newDivisionList = divisions.map(x => ({ id: x, name: x }));
        setDivisionList(newDivisionList);
    }

    function handleNewTeamName(e: React.ChangeEvent<HTMLInputElement>) {
        setNewTeamName(e.target.value);
    }

    function handleNewTeamDivision(e: React.ChangeEvent<HTMLInputElement>) {
        setNewTeamDivision(e.target.value);
    }

    async function handleDeleteTeam(teamId: string): Promise<boolean> {
        if (orgSeason) {
            await deleteRequest.request(orgSeason.id, teamId);
            refreshOrgSeason();
        }

        return true;
    }

    async function addTeam() {

        if (!newTeamName) {
            setNewTeamNameError('required');
            return;
        }

        if (orgSeason) {
            const addTeamCommand = { name: newTeamName, division: newTeamDivision };
            await updateRequest.request(orgSeason.id, addTeamCommand);
            refreshOrgSeason();
        }
    }

    return (<>
        <Grid container spacing={3} alignItems="center">
            <Grid item><h3>Manage Teams</h3></Grid>
        </Grid>
        <BasicCard>

            {orgSeason &&
                <Box>

                    {updateRequest.loading && <CircularProgress />}
                    {deleteRequest.loading && <CircularProgress />}
                    {!updateRequest.loading && !deleteRequest.loading &&

                        <Grid container spacing={1} >
                            <Grid>
                                <BasicTextBox id='txtTeamName'
                                    label='Team Name*'
                                    errorMessage={newTeamNameError}
                                    required
                                    maxLength={20}
                                    width={250}
                                    value={newTeamName}
                                    onChange={handleNewTeamName} />
                            </Grid>

                            <Grid>
                                <BasicDropDown id='txtDivision'
                                    label='select division'
                                    width={200}
                                    value={newTeamDivision}
                                    items={divisionList}
                                    onChange={handleNewTeamDivision} />
                            </Grid>

                            <Grid>
                                <BasicButton onClick={addTeam} labelText="Add" />
                            </Grid>

                        </Grid>}

                    {updateRequest.error && <BasicErrorLabel errors={[updateRequest.error]} />}
                    {deleteRequest.error && <BasicErrorLabel errors={[deleteRequest.error]} />}
                    {teamRows && <MiniGrid rows={teamRows} onDelete={handleDeleteTeam} />}
                </Box>
            }
        </BasicCard>
    </>
    );
}