import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

export interface BasicTextProps {
    id: string,
    errorMessage?: string,
    required?: boolean,
    email?: boolean,
    label: string,
    value: any,
    maxLength: number,
    width: number,
    onChange: (React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>)
}

export function BasicTextBox(props: BasicTextProps) {

    const [isError, setIsError] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>('');


    useEffect(() => {
        if (props.errorMessage) {
            console.log(`There is a props error message`);
            setIsError(true);
            setErrorText(props.errorMessage);
        }

    }, [props.errorMessage]);

    function validate(textValue: string) {

        let hasError = false;
        let errorMessage = '';

        if (props.required && !textValue) {
            hasError = true;
            errorMessage += ' required '
        }
        else if (textValue && props.email) {
            const isValidEmail = validateEmail(textValue);
            if (!isValidEmail) {
                hasError = true;
                errorMessage += ' invalid email '
            }
        }

        setIsError(hasError);
        setErrorText(errorMessage);
    }

    function handleValidate(e: React.FocusEvent<HTMLInputElement>) {
        const textValue = e.target.value;
        validate(textValue);
    }

    function validateEmail(email: string): boolean {
        const isValid = /\S+@\S+\.\S+/.test(email);
        return isValid;
    }


    return (
        <>
            <TextField id={props.id}
                error={isError}
                label={props.label}
                variant="outlined"
                size="small"
                inputProps={{ maxLength: props.maxLength, style: { backgroundColor: "white" } }}
                value={props.value}
                style={{ width: props.width, marginRight: 10, marginBottom: '5px' }}
                helperText={isError ? errorText : ''}
                onBlur={handleValidate}
                onChange={props.onChange} />
        </>
    )
}