import { Grid, Card, Box } from '@mui/material';
import { Link } from 'react-router-dom';

export function CardInstructions(){

    const writePage = () => {
        return (

            <Grid item container direction='column' spacing={2} >
                <Grid item>

                    <h1>Card Instructions</h1>

                </Grid>


                <Grid item>
                    <Card variant="outlined" sx={{ padding: 2 }}>
                        <Grid container>
                            <Grid item xs={6}>
                                1. Generate a trading card &nbsp;
                                <Link to='/trading-card-generator'>
                                    here
                                </Link>
                                &nbsp;
                                and save the PDF
                            </Grid>
                            <Grid item container xs={6}>
                                <Grid item> <img src='/images/products/CardFront.png' alt='pdf' height='150' /></Grid>
                                <Grid item><img src='/images/products/CardBack.png' alt='pdf' height='150' /></Grid>
                            </Grid>
                        </Grid>

                    </Card>
                </Grid>

                <Grid item>

                    <Card variant="outlined" sx={{ padding: 2 }}>
                        <Grid container>
                            <Grid item xs={6}>
                                2. Print the PDF on double sided photo paper using a standard ink jet printer.
                                When you flip the paper in the printer the back will line up with the front.<br />
                                <a href='https://www.amazon.com/gp/product/B075CGPMN9'>Photo Paper at Amazon</a>
                            </Grid>
                            <Grid item xs={6}>
                                <img src='/images/products/printer.png' alt='printer' height='150' />
                            </Grid>
                        </Grid>

                    </Card>


                </Grid>

                <Grid item>
                    <Card variant="outlined" sx={{ padding: 2 }}>
                        <Grid container>
                            <Grid item xs={6}>
                                3. Cut the cards out on the border lines. Use the inner border if you will be putting the
                                cards into lamination sheets.<br />
                                <a href='https://www.amazon.com/dp/B07LFH2MGH'>Paper Cutter at Amazon</a>
                            </Grid>
                            <Grid item xs={6}>
                                <img src='/images/products/Cutter.png' height='150' alt='pdf' />
                            </Grid>
                        </Grid>

                    </Card>

                </Grid>

                <Grid item>
                    <Card variant="outlined" sx={{ padding: 2 }}>
                        <Grid container>
                            <Grid item xs={6}>
                                4. Put the cards into a 2-1/2 X 3-1/2 7mil lamination pouch an laminate 2 times.<br />
                                <a href='https://www.amazon.com/gp/product/B0010JEJPC'>Laminator at Amazon</a><br />
                                <a href='https://www.amazon.com/Scotch-Thermal-Laminating-3-8-Inches-TP5904-20/dp/B00MFTLOCC'>Lamination Pouches at Amazon</a>
                            </Grid>
                            <Grid item xs={6}>
                                <img src='/images/products/laminator.png' height='150' alt='pdf' />

                            </Grid>
                        </Grid>

                    </Card>

                </Grid>

            </Grid>
        );
    }


    return ( <Box>
                {writePage()}
            </Box>
    );
};