import {IconButton } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';

export interface BasicCardProps {
    labelText: string,
    navigateUrl: string
}

export function BasicBackButton(props: BasicCardProps) {

    const navigate = useNavigate();

    return (
        <IconButton aria-label="back" onClick={(e) => navigate(props.navigateUrl)} >
            <ArrowBackIcon />{props.labelText}
        </IconButton>
    );

}