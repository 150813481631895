import { Button } from "@mui/material";

export interface ButtonItem{
    labelText: string,
    onClick: () => void
}

export interface BasicCardProps {
    labelText: string,
    onClick: () => void
}

export function BasicButton(props: BasicCardProps) {

    return (
        <Button color="primary"
            style={{ marginBottom: "5px" }}
            variant="contained"
            onClick={props.onClick}
            component="span">
            {props.labelText}
        </Button>
    );

}