import React, { useEffect, useState } from "react";
import { Grid, Box, CircularProgress, Button } from '@mui/material';
import { BasicCard } from "../../shared/basicCard";
import { useApi } from "../../hooks/useApi";
import { BasicErrorLabel } from "../../shared/basicErrorLabel";
import { addUserPermissionApi, getUsersByOrgIdApi, removeUserPermissionApi } from "../../api/userApi";
import { MiniGrid, RowItem } from "../../shared/miniGrid";
import { BasicTextBox } from "../../shared/basicTextBox";
import { BasicDropDown } from "../../shared/basicDropDown";
import { User } from "../../api/models/user";

const roleList = [{ id: 'readonly', name: 'readonly' }, { id: 'standard', name: 'standard' }, { id: 'admin', name: 'admin' }];

export interface ManageUsersPanelProps {
    organizationId: string
}

export function ManageUsersPanel(props: ManageUsersPanelProps) {

    const [userRows, setUserRows] = useState<RowItem[] | undefined>(undefined);
    const [users, setUsers] = useState<User[] | undefined>(undefined);
    const [newUserName, setNewUserName] = useState<string>('');
    const [userNameError, setUserNameError] = useState<string>('');
    const [newUserRole, setNewUserRole] = useState<string>('readonly');

    const getUsersRequest = useApi(getUsersByOrgIdApi);
    const addUserRequest = useApi(addUserPermissionApi);
    const removeUserRequest = useApi(removeUserPermissionApi);

    useEffect(() => {
        initPage();
    }, []);

    useEffect(() => {
        if (getUsersRequest.data) {
            setUsers(getUsersRequest.data);
            mapUsersToRows(getUsersRequest.data);
        }

    }, [getUsersRequest.data]);

    async function initPage() {
        setNewUserName('');
        setUserNameError('');
        setNewUserRole('readonly');
        await getUsersRequest.request(props.organizationId);
    }

    async function mapUsersToRows(responseUsers: User[]) {
        if (responseUsers && responseUsers.length > 0) {
            const tempRows = responseUsers.map((user) => ({
                id: user.id,
                items: [{ name: 'name', value: user.name }, { name: 'role', value: user.permissions![0].access }]
            }));

            setUserRows(tempRows);
        }
    }

    function handleNewUserName(e: React.ChangeEvent<HTMLInputElement>) {
        setNewUserName(e.target.value);
    }

    function handleNewUserRole(e: React.ChangeEvent<HTMLInputElement>) {
        setNewUserRole(e.target.value);
    }

    async function handleAddUser() {
        if (!newUserName) {
            setUserNameError('required');
            return;
        }

        const createUserCommand = { name: newUserName, orgId: props.organizationId, access: newUserRole };
        await addUserRequest.request(createUserCommand);
        await initPage();
    }

    async function handleRemoveUser(id: string): Promise<boolean> {

        const matchUser = users?.find(x => x.id === id);
        if (matchUser) {
            const removeUserCommand = { name: matchUser.name, orgId: props.organizationId };
            await removeUserRequest.request(removeUserCommand);
            await initPage();
            return true;
        }
        else {
            //todo: message that cannot match user
            return false;
        }
    }

    return (<>
        <Grid container spacing={3} alignItems="center">
            <Grid item><h3>Manage Users</h3></Grid>
        </Grid>
        <BasicCard>

            {getUsersRequest.loading && <CircularProgress />}

            {!getUsersRequest.loading &&
                <Box>
                    <Grid container spacing={1} >
                        <Grid>
                            <BasicTextBox id='txtUserName'
                                label='User Name*'
                                errorMessage={userNameError}
                                email
                                maxLength={40}
                                width={350}
                                value={newUserName}
                                onChange={handleNewUserName} />
                        </Grid>

                        <Grid>
                            <BasicDropDown id='txtRole'
                                label='select role'
                                required
                                width={150}
                                value={newUserRole}
                                items={roleList}
                                onChange={handleNewUserRole} />
                        </Grid>

                        <Grid>
                            <Button color="primary" variant="contained" onClick={handleAddUser} component="span"> ADD
                            </Button>
                        </Grid>

                    </Grid>
                    {addUserRequest.error && <BasicErrorLabel errors={[addUserRequest.error]} />}
                    {removeUserRequest.error && <BasicErrorLabel errors={[removeUserRequest.error]} />}
                    {getUsersRequest.error && <BasicErrorLabel errors={[getUsersRequest.error]} />}
                    {userRows && <MiniGrid rows={userRows} onDelete={handleRemoveUser} />}
                </Box>
            }
        </BasicCard>
    </>
    );
}