import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export interface BasicCardProps {
    labelText: string,
    navUrl: string
}

export function NavButton(props: BasicCardProps) {

    const navigate = useNavigate();

    function navigateToPage() {
        navigate(props.navUrl);
    }

    return (
        <Button color="primary"
            variant="contained"
            style={{ marginBottom: "5px" }}
            onClick={navigateToPage}
            component="span">
            {props.labelText}
        </Button>
    );

}