import axios from 'axios';
import { AddUserPermissionCommand, CreateUserCommand, RemoveUserPermissionCommand, SetUserActiveCommand, User } from './models/user';

export async function getPermissions(bearerToken: string): Promise<User | undefined> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/user/permissions`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as User;
}

export async function getAccess(bearerToken: string, orgId: string, targetType: string): Promise<string | undefined> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/user/access/${targetType}/${orgId}`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as string;
}


export async function getIsSuperAdmin(bearerToken: string): Promise<string | undefined> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/user/access/superAdmin`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as string;
}

export async function getUsersApi(bearerToken: string): Promise<User[] | undefined> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/user`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as User[];
}

export async function getUserByIdApi(bearerToken: string, userId: string): Promise<User | undefined> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/user/${userId}`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as User;
}

export async function getUsersByOrgIdApi(bearerToken: string, orgId: string): Promise<User[] | undefined> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/user/organization/${orgId}`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as User[];
}

export async function addUserApi(bearerToken: string, addUserCommand: CreateUserCommand) {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/user`;
  await axios.post(baseUrl + endpoint, addUserCommand, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function setUserIsActiveApi(bearerToken: string, isActiveCommand: SetUserActiveCommand) {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/user/isActive`;
  await axios.put(baseUrl + endpoint, isActiveCommand, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function addUserPermissionApi(bearerToken: string, userPermission: AddUserPermissionCommand) {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/user/permission/add`;
  await axios.put(baseUrl + endpoint, userPermission, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function removeUserPermissionApi(bearerToken: string, userPermission: RemoveUserPermissionCommand) {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/user/permission/remove`;
  await axios.put(baseUrl + endpoint, userPermission, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}


