import React, { useEffect } from 'react';
import { Header } from '../../shared/header';
import { Grid, Button } from '@mui/material';

const TimerAppPage = () => {

    const [isRunning, setIsRunning] = React.useState<boolean>(false);
    const [buttonText, setButtonText] = React.useState<string>('START');
    const [time, setTime] = React.useState<number>(0);
    const [timer, setTimer] = React.useState<number | undefined>(undefined);

    const toggleTimer = () => {
        if (isRunning) {
            stopTimer();
        }
        else {
            startTimer();
        }
    }

    const startTimer = () => {
        console.log('Starting Timer');
        clearInterval(timer);

        setButtonText('STOP');
        setIsRunning(true);

        let newTimer = window.setInterval(() => {
            let newTime = time + 100;
            console.log(`new time is ${newTime}`);
            setTime((time) => time + 100);
        }, 100);

        setTimer(newTimer);

        const audio = new Audio("sounds/gunshot.mp3");
        audio.play();
    }

    const stopTimer = () => {
        console.log('Stopping Timer');
        setButtonText('START');
        setIsRunning(false);
        clearInterval(timer);
        setTimer(undefined);
    }

    const resetTimer = () => {
        console.log('Resetting Timer');
        setButtonText('START');
        setIsRunning(false);
        clearInterval(timer);
        setTimer(undefined);
        setTime(0);
    }



    const changeDetectedHandler = () => {
        stopTimer();
    }


    const mainContent = () => {

        return (
            <Grid item container spacing={2} direction='column'>

                <Grid item>
                    <Button variant='contained' onClick={toggleTimer}>{buttonText}</Button>
                </Grid>

                <Grid item>
                    <Button variant='contained' onClick={resetTimer}>Reset</Button>
                </Grid>

                <Grid item>
                    <h1>{time / 1000} sec</h1>
                </Grid>

                <Grid item>
                    {/* { <MotionDetector IsMonitoring={isRunning} onChangeDetected={changeDetectedHandler} /> } */}
                </Grid>

            </Grid>
        );
    }

    return (<Grid container direction='column'>

        <Grid item>
            <Header />
        </Grid>

        <Grid item container spacing={2}>
            <Grid item xs={false} sm={1} />

            <Grid item xs={12} sm={8} >
                {mainContent()}
            </Grid>

            <Grid item xs={false} sm={1} />
        </Grid>

    </Grid>
    );

}

export { TimerAppPage }