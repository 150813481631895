import { Link } from 'react-router-dom';
import { Box, Grid } from '@mui/material';

export interface OrgHeaderProps {
    orgName?: string,
    orgId?: string,
    orgSeasonName?: string,
    orgSeasonId?: string,
    teamName?: string,
    teamId?: string,
    pageName?: string
}

export function OrgHeader(props: OrgHeaderProps) {

    function getRootLink() {
        return (
            <>
                <Link to={`/orgs`} style={{ color: 'black' }}>
                    orgs
                </Link>
            </>
        )
    }

    function getOrgLink() {
        return (
            <>
                <span> / </span>
                <Link to={`/orgs/${props.orgId}`} style={{ color: 'black' }}>
                    {props.orgName}
                </Link>
            </>
        )
    }


    function getOrgSeasonLink() {
        return (
            <>
                <span> / </span>
                <Link to={`/orgSeason/${props.orgSeasonId}`} style={{ color: 'black' }}>
                    {props.orgSeasonName}
                </Link>
            </>
        )
    }

    function getTeamLink() {
        return (
            <>
                <span> / </span>
                <Link to={`/orgSeason/${props.orgSeasonId}/team/${props.teamId}`} style={{ color: 'black' }}>
                    {props.teamName}
                </Link>
            </>
        )
    }

    function getPageName() {
        return (
            <>
                <span> / </span>
                {props.pageName}
            </>
        )
    }

    return (
        <>
            <Box className='header' sx={{ color: 'black', backgroundColor: 'white', height: 20, padding: 1 }}>
                <Grid container spacing={2} >
                    <Grid item xs={8} >
                        {getRootLink()}
                        {props.orgId && props.orgName && getOrgLink()}
                        {props.orgSeasonId && props.orgSeasonName && getOrgSeasonLink()}
                        {props.teamId && props.teamName && getTeamLink()}
                        {props.pageName && getPageName()}
                    </Grid>

                </Grid>
            </Box>
        </>
    );
}

