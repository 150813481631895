
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { SalesOrderItem } from "../../api/models/productItem";
import { IconButton } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#003974',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));


export interface OrderFormTableProps {
    orderTotal: number,
    orders?: SalesOrderItem[]
    deleteItem: (seqItem: number) => void
}

export function OrderFormTable(props: OrderFormTableProps) {

    const deleteItem = (seq: number) => {
        props.deleteItem(seq);
    }

    return (<>
        <TableContainer component={Paper} sx={{ width: 1000 }}>
            <Table sx={{ width: 1000 }} aria-label="simple table" size='small'>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Seq</StyledTableCell>
                        <StyledTableCell align="left">ID</StyledTableCell>
                        <StyledTableCell align="left">Name</StyledTableCell>
                        <StyledTableCell align="left">Description</StyledTableCell>
                        <StyledTableCell align="left">Qty</StyledTableCell>
                        <StyledTableCell align="left">Price</StyledTableCell>
                        <StyledTableCell align="left">Total</StyledTableCell>
                        <StyledTableCell align="left">&nbsp;</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.orders && props.orders.map((row) => (
                        <TableRow
                            key={row.seq}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <StyledTableCell component="th" scope="row">
                                {row.seq}
                            </StyledTableCell>
                            <StyledTableCell align="left">{row.productId}</StyledTableCell>
                            <StyledTableCell align="left">{row.name}</StyledTableCell>
                            <StyledTableCell align="left">{row.orgName} {row.athleteName}</StyledTableCell>
                            <StyledTableCell align="right">{row.qty}</StyledTableCell>
                            <StyledTableCell align="right">{row.price}</StyledTableCell>
                            <StyledTableCell align="right">{row.total}</StyledTableCell>
                            <StyledTableCell align="right">
                                <IconButton aria-label="delete" onClick={(e) => deleteItem(row.seq)} >
                                    <ClearIcon />
                                </IconButton>
                            </StyledTableCell>
                        </TableRow>
                    ))}
                    {!props.orders && <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <StyledTableCell component="th" scope="row">
                            No Items
                        </StyledTableCell>

                    </TableRow>}
                </TableBody>
            </Table>

        </TableContainer>
        {props.orderTotal > 0 && <p>Total Amount ${props.orderTotal}</p>}
    </>
    );
}