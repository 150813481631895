import { Box, Card } from "@mui/material";
import { ImportResult } from "../../api/models/common";

export interface ImportResultDisplayProps {
    result: ImportResult
}

export function ImportResultDisplay(props: ImportResultDisplayProps) {

    return (<>
        {props.result &&
            <Card style={{ margin: 5, padding: "10px", border: '1px solid #909090', borderRadius: 5, backgroundColor: '#ffffff', color: '#000000', textDecoration: 'none' }}>

                <p>Status: <span style={{ color: '#008000' }}>{props.result.importStatus}</span></p>
                <p>Total Records: <span style={{ color: '#008000' }}>{props.result.totalRecords}</span></p>
                <p>Total Records Imported: <span style={{ color: '#008000' }}>{props.result.totalRecordsImported}</span></p>
                {props.result.errors && props.result.errors.length > 0 && <Box>
                    <p>Errors: </p>
                    <ul>
                        {props.result.errors.map((x, index) => (<li key={index}>{x.name} - {x.errorMessage}</li>))}
                    </ul>

                </Box>}
            </Card>}
    </>
    );
}