import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export function useApi(apiFunc: any) {

    const [data, setData] = useState<any | undefined>(undefined);
    const [status, setStatus] = useState<number | undefined>(undefined);
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState(false);

    const { getAccessTokenSilently } = useAuth0();

    const request = async (...args: any[]) => {
        setLoading(true);
        setStatus(undefined);
        try {
            let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`, });

            const result = await apiFunc(accessToken, ...args);

            if (result) {
                setStatus(200);
                setData(result);
            }

        } catch (error: any) {
            if (error.response?.data) {
                setError(error.response.data.message);
            }
            else {
                setError(error.message);
            }
        } finally {
            setLoading(false);
        }
    };

    return {
        status,
        data,
        error,
        loading,
        request
    };
};


