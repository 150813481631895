import { Box, Button, Grid, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Member } from '../../api/models/member';
import { BasicDropDown } from '../../shared/basicDropDown';
import { BasicButton } from '../../shared/basicButton';
import { DropDownItem } from '../../models/dropDownItemViewModel';
import { Team, TeamSummary } from '../../api/models/team';
import { getSingleOrgSeasonApi } from '../../api/orgSeasonApi';
import { useApi } from '../../hooks/useApi';
import { useMemberContext } from '../../context/memberContext';
import dayjs from 'dayjs';

export interface MemberTabProps {
    member: Member | null,
    onUpdateJerseyNumber: (jerseyNumber: string) => void,
    onUpdateMemberName: (firstName: string, lastName: string) => void,
    onUpdatePosition: (position: string) => void,
    onUpdateTitle: (title: string) => void,
    onUpdate: () => void
}

export default function MemberInfoTab(props: MemberTabProps) {

    const [firstName, setFirstName] = React.useState<string>(props.member?.firstName ?? '');
    const [lastName, setLastName] = React.useState<string>(props.member?.lastName ?? '');
    const [birthdate, setBirthdate] = React.useState<string>(dayjs(props.member?.birthDate)?.format('MM/DD/YYYY') ?? '');
    const [jerseyNumber, setJerseyNumber] = React.useState<string>(props.member?.jerseyNumber?.toString() ?? '');
    const [position, setPosition] = React.useState<string>(props.member?.position?.toString() ?? '');
    const [title, setTitle] = React.useState<string>(props.member?.title?.toString() ?? '');
    const [parentEmail, setParentEmail] = React.useState<string>(props.member?.email ?? '');

    const [team, setTeam] = React.useState<string>(props.member?.team?.name ?? '');
    const [teamList, setTeamList] = React.useState<DropDownItem[]>([]);

    const getRequest = useApi(getSingleOrgSeasonApi);
    const { member, refreshMember } = useMemberContext();

    const { isAuthenticated } = useAuth0();

    useEffect(() => {

    }, []);

    useEffect(() => {
        if (getRequest.data) {
            const teams = getRequest.data.teams;
            initDropDown(teams);
        }
    }, [getRequest.data]);


    function initDropDown(teams: TeamSummary[]) {

        const newTeamList = teams.map(x => ({ id: x.id, name: x.name }));
        setTeamList(newTeamList);
    }

    const handleJerseyNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setJerseyNumber(value);
    };

    const handlePositionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setPosition(value);
    };

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setTitle(value);
    };

    const handleFirstNameChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setFirstName(value);
    };

    const handleLastNameChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setLastName(value);
    };

    const updateMemberName = async () => {
        props.onUpdateMemberName(firstName, lastName);
    };

    const updateJerseyNumber = async () => {
        props.onUpdateJerseyNumber(jerseyNumber);
    };

    const updatePosition = async () => {
        props.onUpdatePosition(position);
    };

    const updateTitle = async () => {
        props.onUpdateTitle(title);
    };

    const updateEmail = async () => {
        
    };


    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setParentEmail(e.target.value);
    };


    const handleChangeTeam = (e: React.ChangeEvent<HTMLInputElement>) => {
        
    };

    const updateTeam = async () => {
        props.onUpdate();
    };


    return (
        <Grid container spacing={3}>
            <Grid container item xs={12} spacing={1}>
                <Grid item xs={3}>

                    <Box>
                        <TextField id="firstName"
                            label="First Name"
                            variant="outlined"
                            size="small"
                            inputProps={{ maxLength: 25 }}
                            value={firstName}
                            style={{ width: 200 }}
                            onChange={handleFirstNameChange} />
                    </Box>

                </Grid>
                <Grid item xs={3}>

                    <Box>
                        <TextField id="lastName"
                            label="Last Name"
                            variant="outlined"
                            size="small"
                            inputProps={{ maxLength: 25 }}
                            value={lastName}
                            style={{ width: 200 }}
                            onChange={handleLastNameChange} />
                    </Box>

                </Grid>
                <Grid item xs={3}>

                    <Box>
                        <TextField id="birthdate"
                            label="BirthDate"
                            variant="outlined"
                            size="small"
                            inputProps={{ maxLength: 25 }}
                            value={birthdate}
                            style={{ width: 200 }}
                            onChange={e => { setBirthdate(e.target.value) }} />
                    </Box>

                </Grid>
                <Grid item xs={3}>

                    <Box>
                        {isAuthenticated && <Button variant='contained' onClick={updateMemberName}>Update Name</Button>}
                    </Box>

                </Grid>
            </Grid>

            {props.member?.role === 'athlete' &&
            <Grid container item xs={12} spacing={1}>

                <Grid item xs={3}>

                    <Box>
                        <TextField id="jerseyNumber"
                            label="Jersey Number"
                            variant="outlined"
                            size="small"
                            inputProps={{ maxLength: 3 }}
                            value={jerseyNumber}
                            style={{ width: 150 }}
                            onChange={handleJerseyNumberChange} />
                    </Box>

                </Grid>

                <Grid item xs={2}>
                    {isAuthenticated && <Button variant='contained' onClick={updateJerseyNumber}>Update</Button>}
                </Grid>

            </Grid>
}

            <Grid container item xs={12} spacing={1}>

<Grid item xs={3}>

    <Box>
        <TextField id="position"
            label="Position"
            variant="outlined"
            size="small"
            inputProps={{ maxLength: 5 }}
            value={position}
            style={{ width: 150 }}
            onChange={handlePositionChange} />
    </Box>

</Grid>

<Grid item xs={2}>
    {isAuthenticated && <Button variant='contained' onClick={updatePosition}>Update</Button>}
</Grid>

</Grid>

{props.member?.role === 'Leader' && 
<Grid container item xs={12} spacing={1}>

<Grid item xs={3}>

    <Box>
        <TextField id="title"
            label="Title"
            variant="outlined"
            size="small"
            inputProps={{ maxLength: 20 }}
            value={title}
            style={{ width: 150 }}
            onChange={handleTitleChange} />
    </Box>

</Grid>

<Grid item xs={2}>
    {isAuthenticated && <Button variant='contained' onClick={updateTitle}>Update</Button>}
</Grid>

</Grid>}

            <Grid container item xs={12} spacing={1}>

                <Grid item xs={3}>
                    <Box>
                        <TextField id="parentEmail"
                            label="ParentEmail"
                            variant="outlined"
                            size="small"
                            helperText='20 character limit'
                            inputProps={{ maxLength: 50 }}
                            value={parentEmail}
                            style={{ width: 275 }}
                            onChange={handleEmailChange} />
                    </Box>
                </Grid>

                <Grid item xs={2}>
                    {isAuthenticated && <Button variant='contained' onClick={updateEmail}>Update</Button>}
                </Grid>
            </Grid>

           
        </Grid>
    )
}