import { useEffect, useState } from "react";
import { Grid, Box, CircularProgress } from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";
import { User } from "../../api/models/user";
import { useParams } from 'react-router-dom';
import {ErrorHeader} from "../../shared/errorHeader";
import { getUserByIdApi } from "../../api/userApi";


export function UpdateUserPage() {
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [user, setUser] = useState<User | undefined>();

    const params = useParams();
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

        useEffect(() => {

            updatePage();
        }, []);

    async function updatePage() {
        let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`, });
        const getUser = await getUserByIdApi(accessToken, params.id!);
        alert(`user id is ${params.id!}`);
        if(getUser){
            setUser(getUser);
        }
    }

    const mainContent = () => {

        return (
            <Box>
                <h3>{user?.name}</h3>
                <Grid container spacing={2}>

                </Grid>
            </Box>
        );
    }

    return (<Box>
        <ErrorHeader errorMessage={errorMessage} />
        {isLoading && <CircularProgress />}
        {user && mainContent()}
    </Box>
    );

}
