import axios from 'axios';
import { AthleteInfo } from './models/athlete';
import { ImageResponse } from './models/common';

export async function getAthlete(athleteGuid: string): Promise<AthleteInfo> {
    const baseUrl = process.env.REACT_APP_API_URL;
    const endpoint = `/api/v1/athlete/${athleteGuid}`;
    const response = await axios.get(baseUrl + endpoint, { timeout: 60000});
    return response.data as AthleteInfo;
  }

  export async function getAthleteProfileImage(athleteGuid: string): Promise<ImageResponse> {
    const baseUrl = process.env.REACT_APP_API_URL;
    const endpoint = `/api/v1/athlete/${athleteGuid}/profile`;
    const response = await axios.get(baseUrl + endpoint, { timeout: 60000});
    return response.data as ImageResponse;
  }
  