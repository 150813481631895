import React, { useEffect, useState } from "react";
import { Grid, Box } from '@mui/material';
import { BasicCard } from "../../shared/basicCard";
import { useApi } from "../../hooks/useApi";
import { BasicErrorLabel } from "../../shared/basicErrorLabel";
import { UploadImageButton } from "../../shared/uploadImageButton";
import { updateOrgSeasonLogoApi } from "../../api/orgSeasonApi";
import { useOrgSeasonContext } from "../../context/orgSeasonContext";

export function ManageLogoPanel() {

    const [logoImageUrl, setLogoImageUrl] = useState<string>('');

    const updateLogoRequest = useApi(updateOrgSeasonLogoApi);
    const { orgSeason, refreshOrgSeason } = useOrgSeasonContext();

    useEffect(() => {
        if (orgSeason) {
            setLogoImageUrl(orgSeason.logoLink);
        }
    }, [orgSeason]);

    async function uploadImage(selectedFile: string) {
        if (orgSeason) {
            const uploadFileData = { image: selectedFile };
            await updateLogoRequest.request(orgSeason.id, uploadFileData);
            refreshOrgSeason();
        }
    }

    return (<>
        <Grid container spacing={3} alignItems="center">
            <Grid item><h3>Update Logo</h3></Grid>
        </Grid>
        <BasicCard>
            {orgSeason &&
                <Box>
                    <Grid container spacing={1} direction='column' >
                        <Grid>
                            <img src={logoImageUrl} height={100} alt='logo' style={{ border: '1px solid #909090' }} /><br />
                            <span style={{ fontSize: '12px' }}>ideal size is 250 X 250 pixels</span>
                        </Grid>

                        <Grid>
                            <UploadImageButton id="uploadImage" onChange={uploadImage} />
                        </Grid>


                    </Grid>
                    {updateLogoRequest.error && <BasicErrorLabel errors={[updateLogoRequest.error]} />}
                </Box>
            }
        </BasicCard>
    </>
    );
}