import React, { useEffect } from "react";
import { Grid, Box, CircularProgress } from '@mui/material';
import { BasicCard } from "../../shared/basicCard";
import { useApi } from "../../hooks/useApi";
import { BasicErrorLabel } from "../../shared/basicErrorLabel";
import { exportMembersApi, getProfileZips } from "../../api/orgSeasonApi";
import { BasicButton } from "../../shared/basicButton";
import { downloadBase64AsFile } from "../../utils/binaryHelper";


export interface ManageExportsPanelProps {
    orgSeasonId: string
}

export function ManageExportsPanel(props: ManageExportsPanelProps) {

    const exportRequest = useApi(exportMembersApi);
    const exportProfileRequest = useApi(getProfileZips);

    useEffect(() => {
        if (exportRequest.data) {
            downloadBase64AsFile(exportRequest.data.data, exportRequest.data.name);
        }

    }, [exportRequest.data]);

    useEffect(() => {
        if (exportProfileRequest.data) {
            downloadBase64AsFile(exportProfileRequest.data.data, exportProfileRequest.data.name);
        }

    }, [exportProfileRequest.data]);


    async function exportData() {
        await exportRequest.request(props.orgSeasonId);
    }

    async function exportProfileData() {
        await exportProfileRequest.request(props.orgSeasonId);
    }

    return (<>
        <Grid container spacing={3} alignItems="center">
            <Grid item><h3>Manage Exports</h3></Grid>
        </Grid>
        <BasicCard>

            <Box>
                <Grid container spacing={1} direction='column'>
                    <Grid>
                        {exportRequest.loading && <CircularProgress />}
                        {!exportRequest.loading && <BasicButton labelText='Export Data' onClick={exportData} />}
                    </Grid>

                    {/* <Grid>
                        {exportProfileRequest.loading && <CircularProgress />}
                        {!exportProfileRequest.loading && <BasicButton labelText='Export Profile Photos' onClick={exportProfileData} />}
                    </Grid> */}
                </Grid>
                {exportRequest.error && <BasicErrorLabel errors={[exportRequest.error]} />}
                {exportProfileRequest.error && <BasicErrorLabel errors={[exportProfileRequest.error]} />}
            </Box>

        </BasicCard>
    </>
    );
}