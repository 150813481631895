import axios from 'axios';
import { StripeClientSecret } from './models/common';
import { SalesOrderCommand } from './models/productItem';

export async function createPaymentIntent(orderItemCommand: SalesOrderCommand): Promise<StripeClientSecret>{
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/order/create-payment-intent`;
  const response = await axios.post(baseUrl + endpoint, orderItemCommand, { timeout: 60000 });
  return response.data as StripeClientSecret;
}


