import React, { useEffect, useState } from "react";
import { Grid, Box, CircularProgress, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { MiniGrid, RowItem } from "../../shared/miniGrid";
import { addUserApi, getUsersApi, setUserIsActiveApi } from "../../api/userApi";
import { User } from "../../api/models/user";
import { BasicCard } from "../../shared/basicCard";
import { useApi } from "../../hooks/useApi";
import { BasicTextBox } from "../../shared/basicTextBox";
import { BasicErrorLabel } from "../../shared/basicErrorLabel";

export function ManageUsersPanel() {

    const [userRows, setUserRows] = useState<RowItem[] | undefined>(undefined);

    const [addUserName, setAddUserName] = useState<string>('');
    const [userNameError, setUserNameError] = useState<string>('');

    const navigate = useNavigate();

    const fetchUsers = useApi(getUsersApi);
    const addUser = useApi(addUserApi);
    const setUserIsActive = useApi(setUserIsActiveApi);

    useEffect(() => {
        initPage();
    }, []);

    useEffect(() => {
        mapUsersToRows(fetchUsers.data);
    }, [fetchUsers.data]);

    async function initPage() {
        await fetchUsers.request();
    }

    async function mapUsersToRows(responseUsers: User[]) {

        if (responseUsers && responseUsers.length > 0) {
            const tempUserRows = responseUsers.map((user) => ({
                id: user.id,
                items: [{ name: 'name', value: user.name }]

            }));

            setUserRows(tempUserRows);
        }
    }

    function handleAddUserName(e: React.ChangeEvent<HTMLInputElement>) {
        setAddUserName(e.target.value);
    }

    async function handleAddUser() {
        const addUserCommand = { name: addUserName };
        await addUser.request(addUserCommand);
    }

    async function navigateToEditUser(id: string): Promise<boolean> {
        navigate(`/user/${id}`);
        return true;
    }

    async function inactivateUser(id: string): Promise<boolean> {
        await setUserIsActive.request(id);
        initPage();
        return false;
    }

    return (
        <BasicCard>
            {fetchUsers.loading && <CircularProgress />}

            {!fetchUsers.loading &&
                <Box>
                    <Grid container>

                        <Grid>

                            <BasicTextBox id='newUserName'
                                label='UserName*'
                                email
                                errorMessage={userNameError}
                                maxLength={40}
                                width={375}
                                value={addUserName}
                                onChange={handleAddUserName} />
                        </Grid>

                        <Grid><Button color="primary" variant="contained" onClick={handleAddUser} component="span"> ADD
                        </Button></Grid>

                    </Grid>
                    {fetchUsers.error && <BasicErrorLabel errors={[fetchUsers.error]} />}

                    {userRows && <MiniGrid rows={userRows} onEdit={navigateToEditUser} onDelete={inactivateUser} />}

                </Box>
            }

        </BasicCard>
    );
}