import React, { useEffect, useState } from "react";
import { Grid, Button, Box } from '@mui/material';
import { OrderFormEntryItem, ProductItem } from "../../api/models/productItem";
import { BasicTextBox } from "../../shared/basicTextBox";
import { BasicCard } from "../../shared/basicCard";
import { BasicDropDown } from "../../shared/basicDropDown";
import { DropDownItem } from "../../models/dropDownItemViewModel";

export interface OrderFormEntryProps {
    products: ProductItem[]
    onAddOrder: (orderDetails: OrderFormEntryItem) => void
}

export function OrderFormEntry(props: OrderFormEntryProps) {

    const [selectedProductId, setSelectedProductId] = useState<string>('');
    const [productList, setProductList] = useState<DropDownItem[]>([]);
    const [organizationName, setOrganizationName] = useState<string>('');
    const [orgNameError, setOrgNameError] = useState<string>('');
    const [athleteName, setAthleteName] = useState<string>('');
    const [athleteNameError, setAthleteNameError] = useState<string>('');
    const [errors, setErrors] = useState<string[]>([]);
    const [quantity, setQuantity] = useState<number>(1);
    const [qtyError, setQtyError] = useState<string>('');

    //initialize the componenet
    useEffect(() => {
        initDropDown();
    }, []);

    //map the items to a way the DDL can display and auto select the first item
    function initDropDown() {
        if (props.products && props.products.length > 0) {
            const mappedProducts = props.products.map(option => ({ id: option.id, name: `${option.name} - $${option.price}` }));
            setProductList(mappedProducts);
            setSelectedProductId(props.products[0].id);
        }
    }

    function selectProduct(event: React.ChangeEvent<HTMLInputElement>) {
        const productId = event.target.value;
        setSelectedProductId(productId);
    };

    function updateOrganizationName(e: React.ChangeEvent<HTMLInputElement>) {
        setOrganizationName(e.target.value);
    }

    function updateAthleteName(e: React.ChangeEvent<HTMLInputElement>) {
        setAthleteName(e.target.value);
    }

    function updateQuantity(e: React.ChangeEvent<HTMLInputElement>) {

        const thisQty = Number(e.target.value);
        if (isNaN(thisQty)) {
            setQtyError('invalid!');
        }
        else {
            setQuantity(thisQty);
        }
    }

    async function addToOrder() {

        if (!organizationName) {
            setOrgNameError('required!');
            //addError('Org Name is required!');
        }
        else if (!organizationName) {
            setAthleteNameError('required!');
            //addError('Athlete Name is required!');
        }
        else {
            const orderDetails = {
                productId: selectedProductId,
                qty: quantity,
                orgName: organizationName,
                athleteName: athleteName
            };

            props.onAddOrder(orderDetails);
            initDropDown();
            setOrganizationName('');
            setAthleteName('');
        }

    }

    function addError(error: string) {
        const tempErrors = [...errors];
        tempErrors.push(error);
        setErrors(tempErrors);
    }


    return (
        <div>
            <BasicCard>
                <Grid container item spacing={0}>
                    <Grid item>

                        <BasicDropDown id='txtProduct'
                            label='select product'
                            required
                            width={300}
                            value={selectedProductId}
                            items={productList}
                            onChange={selectProduct} />

                    </Grid>

                    <Grid>

                        <BasicTextBox id='txtOrganization'
                            label='Organization Name'
                            errorMessage={orgNameError}
                            required
                            maxLength={30}
                            width={200}
                            value={organizationName}
                            onChange={updateOrganizationName} />

                    </Grid>

                    <Grid>
                        <BasicTextBox id='txtAthlete'
                            label='Athlete Name'
                            errorMessage={athleteNameError}
                            required
                            maxLength={30}
                            width={200}
                            value={athleteName}
                            onChange={updateAthleteName} />
                    </Grid>

                    <Grid>
                        <BasicTextBox id='txtQty'
                            label='Quantity'
                            errorMessage={qtyError}
                            required
                            maxLength={3}
                            width={75}
                            value={quantity}
                            onChange={updateQuantity} />
                    </Grid>


                    <Grid><Button color="primary" variant="contained" onClick={addToOrder} component="span" style={{ marginLeft: 10 }}> Add to Order
                    </Button></Grid>

                </Grid>
            </BasicCard>

        </div>
    );
}