import React, { useEffect, useState } from "react";
import { Grid, Box, Button, CircularProgress } from '@mui/material';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CheckoutForm } from './checkoutForm';
import { createPaymentIntent } from "../../api/orderApi";
import { SalesOrderCommand, ProductItem } from "../../api/models/productItem";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY ?? '');

export interface PurchaseFormProps {
    order: SalesOrderCommand
}


export function PurchaseForm(props: PurchaseFormProps) {

    const [clientSecret, setClientSecret] = useState("");

    useEffect(() => {
        getPaymentIntent();
    }, []);

    async function getPaymentIntent() {
        const paymentIntent = await createPaymentIntent(props.order);
        setClientSecret(paymentIntent.clientSecret);
    }

    const options = {
        clientSecret,
    };

    return (<Grid container direction='column'>
        {clientSecret && (
            <Elements options={options} stripe={stripePromise}>
                <CheckoutForm order={props.order} />
            </Elements>
        )}


    </Grid>
    );

}