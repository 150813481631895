import React, { useEffect, useState } from "react";
import { Grid, Box, CircularProgress, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { createOrganizationApi, deleteOrganizationApi, getOrganizations } from '../../api/organizationApi';
import { CreateOrganizationCommand, Organization } from "../../api/models/organization";
import { MiniGrid, RowItem } from "../../shared/miniGrid";
import { BasicCard } from "../../shared/basicCard";
import { useApi } from "../../hooks/useApi";
import { BasicTextBox } from "../../shared/basicTextBox";

export function ManageOrganziationsPanel() {

    const [orgRows, setOrgRows] = useState<RowItem[] | undefined>(undefined);
    const [addOrgName, setAddOrgName] = useState<string>('');
    const [orgNameError, setOrgNameError] = useState<string>('');
    const [addOrgAdminEmail, setAddOrgAdminEmail] = useState<string>('');
    const [orgAdminEmailError, setOrgAdminEmailError] = useState<string>('');

    const navigate = useNavigate();

    const getOrgsApi = useApi(getOrganizations);
    const createOrgApi = useApi(createOrganizationApi);
    const deleteOrgApi = useApi(deleteOrganizationApi);

    useEffect(() => {
        initPage();
    }, []);

    useEffect(() => {
        mapOrgsToRows(getOrgsApi.data);
    }, [getOrgsApi.data]);

    async function initPage() {
        await getOrgsApi.request();
    }

    async function mapOrgsToRows(responseOrgs: Organization[]) {

        if (responseOrgs && responseOrgs.length > 0) {
            const tempOrgRows = responseOrgs.map((orgs) => ({
                id: orgs.id,
                items: [{ name: 'name', value: orgs.name }]

            }));

            setOrgRows(tempOrgRows);
        }
    }

    function handleAddOrgName(e: React.ChangeEvent<HTMLInputElement>) {
        setAddOrgName(e.target.value);
    }

    function handleAddOrgEmail(e: React.ChangeEvent<HTMLInputElement>) {
        setAddOrgAdminEmail(e.target.value);
    }

    async function handleAddOrg() {

        if (!addOrgName) {
            //setErrorMessage('Org Name is required!');
            return;
        }

        const createOrgCommand: CreateOrganizationCommand = { name: addOrgName };

        if (addOrgAdminEmail) {
            createOrgCommand.adminEmails = [addOrgAdminEmail];
        }

        await createOrgApi.request(createOrgCommand);
        initPage();
    }

    async function navigateToEditOrg(id: string): Promise<boolean> {
        navigate(`/orgs/${id}/admin`);
        return true;
    }

    async function deleteOrg(id: string): Promise<boolean> {
        await deleteOrgApi.request(id);
        initPage();
        return false;
    }

    return (
        <BasicCard>

            {getOrgsApi.loading && <CircularProgress />}

            {!getOrgsApi.loading &&
                <Box>
                    <Grid container>

                        <Grid>

                            <BasicTextBox id='txtOrganization'
                                label='Organization Name*'
                                errorMessage={orgNameError}
                                required
                                maxLength={20}
                                width={250}
                                value={addOrgName}
                                onChange={handleAddOrgName} />
                        </Grid>

                        <Grid>
                            <BasicTextBox id='newOrgAdminEmail'
                                label='Org Admin Email'
                                email
                                errorMessage={orgAdminEmailError}
                                maxLength={40}
                                width={375}
                                value={addOrgAdminEmail}
                                onChange={handleAddOrgEmail} />

                        </Grid>

                        <Grid><Button color="primary" variant="contained" onClick={handleAddOrg} component="span"> ADD
                        </Button></Grid>

                    </Grid>
                    {getOrgsApi.error && <p>{getOrgsApi.error}</p>}

                    {orgRows && <MiniGrid rows={orgRows} onEdit={navigateToEditOrg} onDelete={deleteOrg} />}

                </Box>
            }

        </BasicCard>
    );
}