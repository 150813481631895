import React, { useState, useEffect } from "react";

import { Paper } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { styled } from '@mui/material/styles';

import { IconButton } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#003974',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));


export interface RowItem {
  id: string,
  items: TableItem[]
}

export interface TableItem {
  name: string,
  value: string
}

interface DocumentGridInputProps {
  rows: RowItem[],
  onDelete?: (id: string) => Promise<boolean>,
  onSelect?: (id: string) => Promise<boolean>
}

export function DocumentGrid(props: DocumentGridInputProps) {

  function deleteRecord(id: string) {
    if (props.onDelete) {
      const isSuccess = props.onDelete(id);
    }
  }

  function selectRecord(id: string) {
    if (props.onSelect) {
      const isSuccess = props.onSelect(id);
    }
  }

  return (
    <TableContainer component={Paper} sx={{ width: 600 }}>
      <Table sx={{ width: 600 }} aria-label="simple table" size='small'>
        <TableHead>
          <TableRow>
            {props.rows[0].items.map((item) => (
              <StyledTableCell key={item.name} align="left">{item.name}</StyledTableCell>
            ))}

            {props.onSelect && <StyledTableCell align="left" width={40}>&nbsp;</StyledTableCell>}

            {props.onDelete && <StyledTableCell align="left" width={40}>&nbsp;</StyledTableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows && props.rows.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >

              {row.items.map((item, index) => (
                <StyledTableCell key={`${row.id}_${index}`} align="left">{item.value}</StyledTableCell>
              ))}

              {props.onSelect && <StyledTableCell align="left">
                <IconButton aria-label="delete" onClick={(e) => selectRecord(row.id)} >
                  <img src='/images/pdfLogo.png' height='30px' alt='pdf_icon' />
                </IconButton>
              </StyledTableCell>}

              {props.onDelete && <StyledTableCell align="left">
                <IconButton aria-label="delete" onClick={(e) => deleteRecord(row.id)} >
                  <ClearIcon />
                </IconButton>
              </StyledTableCell>}
            </TableRow>
          ))}
        </TableBody>

      </Table>
    </TableContainer>
  );

};
