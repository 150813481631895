import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import { Card, CardActionArea, CardMedia, CardContent,} from '@mui/material';

interface LinkProps {
    linkUrl: string,
    imageUrl: string,
    firstName: string,
    lastName?: string,
    identifier?: string
}

export function LinkTile(props: LinkProps){

    return (
        <Link to={props.linkUrl} style={{ textDecoration: 'none' }}>
            <Card style={{
                width: 110, height: 160, margin: 5, paddingTop: '10px', border: '1px solid #909090',
                borderRadius: 10, backgroundColor: '#c2d7f0', textAlign: 'center', textDecoration: 'none'
            }}>
                <CardActionArea>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center"
                        }}
                    >
                        <CardMedia
                            style={{
                                width: "90px",
                                maxHeight: "90px",
                                border: "1px solid #a9a9a9",
                            }}
                            component="img"
                            image={props.imageUrl}
                            title=""
                        />

                    </div>
                    <CardContent>
                        <Box sx={{ fontWeight: 900, fontSize: 12 }}>
                            {props.firstName}<br /> {props.lastName}<br /> {props.identifier}
                        </Box>

                    </CardContent>
                </CardActionArea>
            </Card>
        </Link>


    );

}