import axios from 'axios';
import { ProductList } from './models/productItem';

export async function getProducts(): Promise<ProductList>{
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/product`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000 });
  return response.data as ProductList;
}



