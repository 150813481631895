import React, { useState } from "react";
import { Box } from '@mui/material';


export const CheckoutStatusPage: React.FC = (): JSX.Element => {

    function mainContent() {
        return (
            <Box>
                <h1>Thank you for your purchase !</h1>
                <p>An email confirmation will be sent to you.</p>
            </Box>
        );
    }

    return (<>
        {mainContent()}
    </>
    );
}