import React, { useEffect, useState } from "react";
import { Grid, Button, Box, CircularProgress } from '@mui/material';
import { getProducts } from "../../api/productApi";
import { OrderFormEntryItem, SalesOrderItem, SalesOrderCommand, ProductItem, ProductList } from "../../api/models/productItem";
import { PurchaseForm } from "./purchaseForm";
import { OrderFormTable } from "./orderFormTable";
import { OrderFormEntry } from "./orderFormEntry";
import { BasicTextBox } from "../../shared/basicTextBox";
import { useApi } from "../../hooks/useApi";

export const OrderFormPage: React.FC = (): JSX.Element => {

    const [isPlaceOrder, setIsPlaceOrder] = useState<boolean>(true);
    const [isMakePayment, setIsMakePayment] = useState<boolean>(true);

    const [productList, setProductList] = useState<ProductItem[]>([]);

    const [orderTotal, setOrderTotal] = useState<number>(0);
    const [order, setOrder] = useState<SalesOrderCommand | undefined>(undefined);
    const [orderItems, setOrderItems] = useState<SalesOrderItem[] | undefined>(undefined);

    const [email, setEmail] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');

    const getProductsApi = useApi(getProducts);

    //when page loads init the page
    useEffect(() => {
        initPage();
    }, []);

    //retrieve the product list uisng useApi hook
    async function initPage() {
        await getProductsApi.request();
    }

    //when the data from the useApi hook is updated, trigger to retrieve the data
    useEffect(() => {
        loadProductList(getProductsApi.data);
    }, [getProductsApi.data]);

    //set the data from load product list into productList state on this page
    async function loadProductList(productList: ProductList) {
        if (productList && productList.products) {
            setProductList(productList.products);
        }

    }


    //when a new product is selected to the order form, add it to the order
    function handleNewOrderEntry(orderDetails: OrderFormEntryItem) {

        //find the selected product by Id from form to get the details
        const selectedItem = productList.find(x => x.id === orderDetails.productId);

        //if product was found, add it to the order list
        if (selectedItem) {
            const order = {
                seq: -1,
                productId: orderDetails.productId,
                name: selectedItem.name,
                price: selectedItem.price,
                qty: orderDetails.qty,
                total: selectedItem.price * orderDetails.qty,
                orgName: orderDetails.orgName,
                athleteName: orderDetails.athleteName
            };

            const tempItems = orderItems ? [...orderItems] : [];
            tempItems.push(order);
            console.log(`tempItems`, tempItems);
            console.log(`about to resequence`);

            const reseqItems = reSequence(tempItems);

            console.log(`reseq`, reseqItems);
            setOrderItems(reseqItems);
        }
        else {
            //todo: need to return an error that the productId doesnt exist!
            console.log('Product doesnt exist');
        }
    }

    function reSequence(items: SalesOrderItem[]): SalesOrderItem[] {

        let tempItems: SalesOrderItem[] = [];
        let seq = 1;

        //get the lastest list of orders and re-sequence
        if (items) {
            tempItems = items.map(x => ({
                seq: seq++,
                productId: x.productId,
                name: x.name,
                price: x.price,
                qty: x.qty,
                total: x.total,
                orgName: x.orgName,
                athleteName: x.athleteName
            }));
        }

        //calculate the new order total
        let tempOrderTotal = 0;
        for (const orderItem of tempItems) {
            tempOrderTotal = tempOrderTotal + orderItem.total;
        }
        setOrderTotal(tempOrderTotal);

        return tempItems;

    }

    function deleteItemHandler(seqID: number) {
        if (orderItems) {
            //todo: should we verify ensure the item actually exist ?
            const filteredOrderItems = orderItems.filter(x => x.seq !== seqID);
            const reseqItems = reSequence(filteredOrderItems);
            setOrderItems(reseqItems);
        }
        else {
            //todo: error if there is no item to delete from
            //todo: should be have a unique Guid to use to delete ?
        }

    }

    //once the customer has created his order and entered his info, ready to make payment
    function submitOrder() {

        setIsMakePayment(true);
        setIsPlaceOrder(false);

        if (!orderItems || orderItems.length === 0) {
            //todo: error message and / or set fields as error
            return;
        }

        const tempOrderItemCommand: SalesOrderCommand = {
            email: email,
            firstName: firstName,
            lastName: lastName,
            totalAmount: orderTotal,
            items: orderItems
        };
        setOrder(tempOrderItemCommand);
    }

    function updateEmail(e: React.ChangeEvent<HTMLInputElement>) {
        setEmail(e.target.value);
    }

    function updateFirstName(e: React.ChangeEvent<HTMLInputElement>) {
        setFirstName(e.target.value);
    }

    function updateLastName(e: React.ChangeEvent<HTMLInputElement>) {
        setLastName(e.target.value);
    }

    function getOrderForm() {
        return (
            <Grid item container spacing={2} justifyContent="flex-start" alignItems="center" >
                <Grid item xs={12} >
                    <h1>Order Form</h1>
                </Grid>

                <Grid item xs={12}>
                    {productList &&
                        <Box sx={{ marginLeft: '10px' }}>
                            <OrderFormEntry products={productList} onAddOrder={handleNewOrderEntry} />
                        </Box>
                    }
                </Grid>

                <Grid item xs={12}>
                    <Box sx={{ marginLeft: '10px' }}>
                        {<OrderFormTable orders={orderItems} orderTotal={orderTotal} deleteItem={deleteItemHandler} />}
                    </Box>
                </Grid>

                <h3>Contact Info</h3>

                <Grid item xs={12}>
                    <BasicTextBox id='txtEmail'
                        required
                        email
                        label='Email'
                        maxLength={40}
                        width={400}
                        value={email}
                        onChange={updateEmail} />
                </Grid>

                <Grid item xs={12}>

                    <BasicTextBox id='txtFirstName'
                        required
                        label='FirstName'
                        maxLength={40}
                        width={400}
                        value={firstName}
                        onChange={updateFirstName} />
                </Grid>

                <Grid item xs={12}>
                    <BasicTextBox id='txtLastName'
                        required
                        label='LastName'
                        maxLength={40}
                        width={400}
                        value={lastName}
                        onChange={updateLastName} />
                </Grid>

                <Grid item xs={12}>
                    <Button color="primary"
                        disabled={!orderItems || !email || !firstName || !lastName}
                        variant="contained"
                        onClick={submitOrder}
                        component="span"
                        style={{ marginLeft: 10 }}>
                        Submit Order
                    </Button>
                </Grid>
            </Grid>

        );
    }

    function mainContent() {
        return (
            <Box>
                {isPlaceOrder && getOrderForm()}
                {isMakePayment && order &&
                    <PurchaseForm order={order} />
                }
            </Box>
        );
    }

    return (<>
        {getProductsApi.loading && <CircularProgress />}
        {getProductsApi.error && <p>getProductsApi.error</p>}
        {!getProductsApi.loading && getProductsApi.data && mainContent()}
    </>
    );

}