import { createContext, useContext } from "react"
import { OrgSeason } from "../api/models/orgSeason"

export type OrgSeasonContent = {
    orgSeason: OrgSeason | undefined
    refreshOrgSeason: () => void
}
export const OrgSeasonContext = createContext<OrgSeasonContent>({
    orgSeason: undefined, // set a default value
    refreshOrgSeason: () => { },
})

export const useOrgSeasonContext = () => useContext(OrgSeasonContext)