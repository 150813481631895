import { Checkbox, FormControlLabel } from "@mui/material";

export interface BasicCheckBoxProps {
    label: string,
    checked?: boolean,
    onChange: (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
}

export function BasicCheckBox(props: BasicCheckBoxProps) {
    return (
        <FormControlLabel control={
            <Checkbox checked={props.checked} color='primary' onChange={props.onChange} inputProps={{ 'aria-label': 'controlled' }} />
        } label={props.label} />
    );
}