import { Card } from "@mui/material";


export interface BasicErrorLabelProps {
    errors: String[]
}

export function BasicErrorLabel(props: BasicErrorLabelProps) {

    return (<>
        {props.errors && props.errors.length &&
            <Card style={{ margin: 5, padding: 1, border: '1px solid #909090', borderRadius: 5, backgroundColor: '#ffffff', color: '#ff0000', textDecoration: 'none' }}>
                <ul>
                    {props.errors.map((x, index) => (<li key={index}>{x}</li>))}
                </ul>

            </Card>}
    </>
    );

}