import React from 'react';
import { Button, Input, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { convertBlobToBase64Url } from '../utils/binaryHelper';
import { BasicDropDown } from './basicDropDown';

const docTypeList = [{ id: 'Birth Certificate', name: 'Birth Certificate' },
{ id: 'Report Card', name: 'Report Card' },
{ id: 'Physical', name: 'Physical' },]

interface UploadFileProps {
  id: string,
  accept?: string,
  selectDocType?: boolean,
  onChange: (selectedFile: string, fileName: string, mimeType: string, size: number, documentType?: string) => void
}

export function UploadFileButton(props: UploadFileProps) {

  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [dataFile, setDataFile] = React.useState<File | null>(null);
  const [pageState, setPageState] = React.useState<string>('none');

  const [documentType, setDocumentType] = React.useState<string>('');


  async function handleClose() {
    setOpenDialog(false);
  }

  function handleNewDocType(e: React.ChangeEvent<HTMLInputElement>) {
    setDocumentType(e.target.value);
  }

  async function saveFile() {

    if (dataFile) {
      const fileAsString = await convertBlobToBase64Url(dataFile) as string;
      props.onChange(fileAsString, dataFile.name, dataFile.type, dataFile.size, documentType);
    }

    setOpenDialog(false);
  }


  function isState(state: string): boolean {
    return state === pageState;
  }

  const onFileSelected = async ({ target }: any) => {

    try {
      const thisFile = target.files[0] as File;
      setDataFile(thisFile);
      setPageState('fileSelected');
      setOpenDialog(true);
      target.value = null;
    }
    catch (error: any) {
      console.log(error);
    }

  }

  return (
    <>
      <Grid container>
        <Grid item xs={3}>
          <label htmlFor={props.id}>
            <Input id={props.id} sx={{ display: 'none' }} inputProps={{ accept: props.accept }} type="file"  onChange={onFileSelected} />
            <Button color="secondary" variant="contained" component="span">
              Import
            </Button><br/>
            <span style={{ fontSize: 'medium' }}>accepts .csv</span>
          </label>
        </Grid>
      </Grid>


      <Dialog open={openDialog} sx={{ width: 1000 }}  >
        <DialogTitle>Import Document</DialogTitle>

        <DialogContent sx={{ width: 500 }}>
          {dataFile && <p> fileName :  {dataFile.name} </p>}
          {props.selectDocType && <BasicDropDown id='txtDoctype'
            label='select document type'
            required
            width={200}
            value={documentType}
            items={docTypeList}
            onChange={handleNewDocType} />

          }

        </DialogContent>

        <DialogActions>
          {isState('fileSelected') && <Button onClick={saveFile}>Import</Button>}
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>



    </>
  );

}