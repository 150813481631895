import React, { useEffect, useState } from "react";
import { Grid, Box, CircularProgress } from '@mui/material';
import { BasicCard } from "../../shared/basicCard";
import { useApi } from "../../hooks/useApi";
import { BasicErrorLabel } from "../../shared/basicErrorLabel";
import { UploadImageButton } from "../../shared/uploadImageButton";
import { getSingleOrganizationApi, updateOrganizationLogoApi } from '../../api/organizationApi';

export interface ManageLogoPanelProps {
    organizationId: string
}

export function ManageLogoPanel(props: ManageLogoPanelProps) {

    const [logoImageUrl, setLogoImageUrl] = useState<string>('');

    const getOrganizationRequest = useApi(getSingleOrganizationApi);
    const updateLogoRequest = useApi(updateOrganizationLogoApi);

    useEffect(() => {
        initPage();
    }, []);

    useEffect(() => {
        if (getOrganizationRequest.data) {
            setLogoImageUrl(getOrganizationRequest.data.logoLink);
           
        }

    }, [getOrganizationRequest.data]);

    async function initPage() {
        await getOrganizationRequest.request(props.organizationId);
    }

    async function uploadImage(selectedFile: string) {
        const uploadFileData = { image: selectedFile };
        await updateLogoRequest.request(props.organizationId, uploadFileData);
        await initPage();
    }

    return (<>
        <Grid container spacing={3} alignItems="center">
            <Grid item><h3>Update Logo</h3></Grid>
           
        </Grid>
        <BasicCard>

            {getOrganizationRequest.loading && <CircularProgress />}

            {!getOrganizationRequest.loading &&
                <Box>
                    <Grid container spacing={1} direction='column' >
                        <Grid>
                            <img src={logoImageUrl} height={100} alt='logo' style={{ border: '1px solid #909090' }} /><br />
                            <span style={{ fontSize: '12px' }}>ideal size is 250 X 250 pixels</span>
                        </Grid>

                        <Grid>
                            <UploadImageButton id="uploadImage" onChange={uploadImage} />
                        </Grid>


                    </Grid>
                    {getOrganizationRequest.error && <BasicErrorLabel errors={[getOrganizationRequest.error]} />}
                </Box>
            }
        </BasicCard>
    </>
    );
}