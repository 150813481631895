import { useEffect, useState } from "react";
import { Box,CircularProgress } from '@mui/material';
import { ErrorHeader } from "../../shared/errorHeader";
import { AthleteExperience, AthleteInfo} from "../../api/models/athlete";
import { useParams } from "react-router-dom";
import { getAthlete, getAthleteProfileImage } from "../../api/athleteApi";

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { MiniGrid, RowItem } from "../../shared/miniGrid";

dayjs.extend(duration);


export function AthletePage() {

    const [athlete, setAthlete] = useState<AthleteInfo | undefined>(undefined);
    const [experienceRows, setExperienceRows] = useState<RowItem[] | undefined>(undefined);
    const [memberProfileImage, setMemberProfileImage] = useState<string | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const params = useParams();

    const birthDate = dayjs(athlete?.birthDate);
    const age = dayjs().diff(birthDate, 'year');


    useEffect(() => {

        initPage();
    }, []);

    const initPage = async () => {
        try {

            setIsLoading(true);
            setErrorMessage('');

            if (!params.athleteGuid) {
                throw Error('Athlete GUID is missing');
            }

            await refreshMember(params.athleteGuid);
            await refreshMemberProfileImage(params.athleteGuid);
            setIsLoading(false);


        } catch (error: any) {
            if (error.response?.data) {
                setErrorMessage(error.response.data.message);
            }
            else {
                setErrorMessage(error.message);
            }
            setIsLoading(false);
        }

    }

    function mapExperienceToRows(responseExperience: AthleteExperience[]) {
        if (responseExperience && responseExperience.length > 0) {
            const tempRows = responseExperience.map((exp) => ({
                id: exp.startDate.toString(),
                items: [{ name: 'date', value:dayjs(exp.startDate).format('MM/YY') }, 
                        { name: 'org', value: exp.orgName },
                        { name: 'season', value: exp.seasonName },
                        { name: 'level', value: exp.level }
                    ]
            }));

            setExperienceRows(tempRows);
        }
    }

    async function refreshMember(athleteGuid: string) {
        const response = await getAthlete(athleteGuid)
        setAthlete(response);
        if (response.experiences) {
            mapExperienceToRows(response.experiences);
        }
    }

    async function refreshMemberProfileImage(athleteGuid: string) {
        const imageResponse = await getAthleteProfileImage(athleteGuid);
        setMemberProfileImage(imageResponse.image);
    }



    const mainContent = () => {

        return (
            
                <Box>
                    <br/>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={athlete?.orgSeason?.logoLink} height={50} alt='profile' />
                    {athlete?.orgSeason && <h2 style={{ margin: '0 10px' }}>{athlete?.orgSeason.name}</h2>}
                    {athlete?.team && <h2 style={{ margin: '0 10px' }}>{athlete?.team.name}</h2>}
                </div>

                    <h1>{athlete?.firstName}  {athlete?.lastName}  - #{athlete?.jerseyNumber}</h1>
                    {<img src={memberProfileImage} height={200} alt='profile' />}
                    {athlete?.qrCodeLink && <img src={athlete?.qrCodeLink} height={200} alt='profile' />}

                    <br />
                    
                    <br/><br/>
                    <h1>Stats</h1>
                    <p>height:{athlete?.height}</p>
                    <p>weight:{athlete?.weight}</p>
                    <p>position: {athlete?.position}</p>
                    <p>birthDate:{birthDate.format('DD/MM/YY')}</p>
                    <p>age: {age}</p>
                    <br/><br/>


                    <h1>Experience</h1>
                    {experienceRows && <MiniGrid rows={experienceRows} />}

                    <br/><br/>

                    <h1>Videos</h1>
                    {athlete?.videos && athlete?.videos.map((video) => (
                        <div key={video.videoLink}>
                            <a href={video.videoLink} target="_blank" rel="noopener noreferrer">
                            <img src={video.thumbnailLink} alt={video.title} style={{ width: '150px', height: 'auto' }} />
                            <p>{video.title}</p>
                            </a>
                        </div>
                    ))}

                    <br/><br/>

                    <h1>Transcripts</h1>

                    <br/><br/>
                   

                    <br />

                </Box>
        );
    }

    return (<Box>
        <ErrorHeader errorMessage={errorMessage} />
        {isLoading && <CircularProgress />}
        {athlete && mainContent()}
    </Box>
    );

}