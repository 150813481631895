import React, { useEffect, useState } from "react";
import { Grid, Box } from '@mui/material';
import { BasicCard } from "../../shared/basicCard";
import { useApi } from "../../hooks/useApi";
import { BasicErrorLabel } from "../../shared/basicErrorLabel";
import { updateOrgSeasonNameApi } from "../../api/orgSeasonApi";
import { BasicTextBox } from "../../shared/basicTextBox";
import { useOrgSeasonContext } from "../../context/orgSeasonContext";
import { BasicButton } from "../../shared/basicButton";


export function ManageNamePanel() {

    const [newOrgSeasonName, setNewOrgSeasonName] = useState<string>('');
    const [newOrgSeasonNameError, setNewOrgSeasonNameError] = useState<string>('');

    const updateRequest = useApi(updateOrgSeasonNameApi);
    const { orgSeason, refreshOrgSeason } = useOrgSeasonContext();

    useEffect(() => {
        if (orgSeason) {
            setNewOrgSeasonName(orgSeason.name);
        }

    }, [orgSeason]);

    //update the local value for the name
    function handleUpdateOrgSeasonName(e: React.ChangeEvent<HTMLInputElement>) {
        setNewOrgSeasonName(e.target.value);
    }

    //change the orgSeason name
    async function updateData() {

        if (!newOrgSeasonName) {
            setNewOrgSeasonNameError('required');
            return;
        }

        const updateDataItem = { name: newOrgSeasonName };
        await updateRequest.request(orgSeason?.id, updateDataItem);
        refreshOrgSeason();
    }

    return (<>
        <Grid container spacing={3} alignItems="center">
            <Grid item><h3>Update Name</h3></Grid>
        </Grid>
        <BasicCard>

            {orgSeason &&
                <Box>
                    <Grid container spacing={1} >
                        <Grid>
                            <BasicTextBox id='txtOrgSeasonName'
                                label='OrgSeason Name*'
                                errorMessage={newOrgSeasonNameError}
                                maxLength={20}
                                width={250}
                                value={newOrgSeasonName}
                                onChange={handleUpdateOrgSeasonName} />
                        </Grid>

                        <Grid>
                            <BasicButton onClick={updateData} labelText="Update" />

                        </Grid>

                    </Grid>
                    {updateRequest.error && <BasicErrorLabel errors={[updateRequest.error]} />}
                </Box>
            }
        </BasicCard>
    </>
    );
}
