import React from "react";
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Grid, Box, Button, CircularProgress, Card } from '@mui/material';
import { SalesOrderCommand } from "../../api/models/productItem";
import { BasicCard } from "../../shared/basicCard";

export interface CheckoutFormProps {
    order: SalesOrderCommand
}

export function CheckoutForm(props: CheckoutFormProps) {

    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async () => {

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            return;
        }

        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/checkout-status`,
            },
        });

        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            console.log(result.error.message);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    return (
        <>
            <h1> Submit Payment</h1>

            <h3>Total Amount ${props.order.totalAmount} </h3>
            <h4>Email: {props.order.email} </h4>
            <BasicCard>
                <form style={{ width: 500 }}>
                    <PaymentElement />
                    <br />
                    <Button disabled={!stripe} color="primary" variant="contained" onClick={handleSubmit} component="span"> Pay Now
                    </Button>

                </form>
            </BasicCard>


        </>
    );

}