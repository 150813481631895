import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { downloadBase64AsFile } from "../../utils/binaryHelper";
import { Grid, Box, Button, CircularProgress } from '@mui/material';
import { UploadImageButton } from '../../shared/uploadImageButton';
import { OrgHeader } from "../../shared/orgHeader";
import { ErrorHeader } from "../../shared/errorHeader";
import { getAccess } from "../../api/userApi";
import {
    getMember,
    updateMemberProfileImage,
    updateMemberActionImage,
    updateMemberMetaData,
    addMemberDocumentApi,
    updateMemberJerseyNumber,
    updateMemberPosition,
    updateMemberTitle,
    viewMemberTradingCards,
    generateMemberTradingCards,
    deleteMember,
    updateMemberName,
    getMemberProfileImage,
    getMemberActionImage,
    generateBinderSheet
} from '../../api/memberApi';

import MemberTabs from "./memberTabs";
import { Member, MemberMetaData } from "../../api/models/member";
import { BasicBackButton } from "../../shared/basicBackButton";
import { BasicMenu } from "../../shared/basicMenu";
import { MemberContext } from "../../context/memberContext";


export function MembersPage() {

    const [member, setMember] = useState<Member | undefined>(undefined);
    const [isLoadingInfo, setIsLoadingInfo] = useState<boolean>(false);
    const [isLoadingCardData, setIsLoadingCardData] = useState<boolean>(false);
    const [isLoadingDocument, setIsLoadingDocument] = useState<boolean>(false);
    const [isLoadingProfile, setIsLoadingProfile] = useState<boolean>(false);
    const [isLoadingAction, setIsLoadingAction] = useState<boolean>(false);
    const [isLoadingGenerateCards, setIsLoadingGenerateCards] = useState<boolean>(false);
    const [isLoadingDownloadCards, setIsLoadingDownloadCards] = useState<boolean>(false);
    const [isLoadingDeleteMember, setIsLoadingDeleteMember] = useState<boolean>(false);

    const [memberProfileImage, setMemberProfileImage] = useState<string | undefined>(undefined);
    const [memberActionImage, setMemberActionImage] = useState<string | undefined>(undefined);

    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [accessLevel, setAccessLevel] = useState<string | undefined>(undefined);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const params = useParams();
    const navigate = useNavigate();
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    useEffect(() => {

        initPage();
    }, []);

    const initPage = async () => {
        try {

            setIsLoading(true);
            setErrorMessage('');

            if (!params.orgSeasonId) {
                throw Error('OrgSeason ID is missing');
            }

            if (!params.memberId) {
                throw Error('Member ID is missing');
            }


            let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`, });

            const access = await getAccess(accessToken, params.orgSeasonId, "orgSeason");
            setAccessLevel(access);
            setIsAdmin(access === "admin" || access === "superadmin");

            if (!access) {
                throw Error('You dont have permission to access this data');
            }

            await refreshMember(params.memberId);
            await refreshMemberProfileImage(params.memberId);
            await refreshMemberActionImage(params.memberId);
            setIsLoading(false);


        } catch (error: any) {
            if (error.response?.data) {
                setErrorMessage(error.response.data.message);
            }
            else {
                setErrorMessage(error.message);
            }
            setIsLoading(false);
        }

    }






    async function refreshMember(memberId: string) {
        const accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}` });
        const member = await getMember(accessToken, memberId)
        setMember(member);
    }

    async function refreshMemberProfileImage(memberId: string) {
        const accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}` });
        const imageResponse = await getMemberProfileImage(accessToken, memberId);
        setMemberProfileImage(imageResponse.image);

    }

    async function refreshMemberActionImage(memberId: string) {
        const accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}` });
        const imageResponse = await getMemberActionImage(accessToken, memberId);
        setMemberActionImage(imageResponse.image);
    }

    async function handlePageRefresh() {
        alert('Refresh Page !');
        initPage();
    }

    async function updateContactInfo(phone: string, email: string) {
        //update contact info
    }


    const handleChangeProfile = async (selectedImage: string): Promise<void> => {

        let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`, });

        if (!params.orgSeasonId) {
            throw Error('OrgSeason ID is missing');
        }

        if (!params.memberId) {
            throw Error('Leader ID is missing');
        }

        setIsLoadingProfile(true);
        const addImageCommand = { image: selectedImage };
        await updateMemberProfileImage(accessToken, params.memberId, addImageCommand);
        await refreshMemberProfileImage(params.memberId);
        initPage();

    }

    const handleChangeAction = async (selectedImage: string): Promise<void> => {

        let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`, });

        if (!params.orgSeasonId || !member) {
            throw Error('An Id is missing');
        }
        setIsLoadingAction(true);
        const addImageCommand = { image: selectedImage };
        await updateMemberActionImage(accessToken, member.id, addImageCommand);
        await refreshMemberActionImage(member.id);
        await initPage();
    }

    const handleSubmitMemberName = async (firstName: string, lastName: string): Promise<void> => {

        let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`, });

        if (!params.orgSeasonId) {
            throw Error('OrgSeason ID is missing');
        }

        if (!params.memberId) {
            throw Error('Leader ID is missing');
        }

        if (member?.id) {
            setIsLoadingInfo(true);
            const updateNameCommand = { firstName: firstName, lastName: lastName };
            await updateMemberName(accessToken, member!.id!, updateNameCommand)

            getMember(accessToken, params.memberId)
                .then((member) => {
                    setMember(member);
                })
                .finally(() => setIsLoadingInfo(false));
        }
    }

    const handleSubmitJerseyNumber = async (jerseyNumber: string): Promise<void> => {

        let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`, });

        if (!params.orgSeasonId) {
            throw Error('OrgSeason ID is missing');
        }

        if (!params.memberId) {
            throw Error('Member ID is missing');
        }

        if (member?.id) {
            setIsLoadingInfo(true);
            const identifierCommand = { jerseyNumber: parseInt(jerseyNumber) };
            await updateMemberJerseyNumber(accessToken, member!.id!, identifierCommand)

            getMember(accessToken, params.memberId)
                .then((member) => {
                    setMember(member);
                })
                .finally(() => setIsLoadingInfo(false));
        }
    }

    const handleSubmitPostion = async (position: string): Promise<void> => {

        let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`, });

        if (!params.orgSeasonId) {
            throw Error('OrgSeason ID is missing');
        }

        if (!params.memberId) {
            throw Error('Member ID is missing');
        }

        if (member?.id) {
            setIsLoadingInfo(true);
            const identifierCommand = { position:position };
            await updateMemberPosition(accessToken, member!.id!, identifierCommand)

            getMember(accessToken, params.memberId)
                .then((member) => {
                    setMember(member);
                })
                .finally(() => setIsLoadingInfo(false));
        }
    }

    const handleSubmitTitle = async (title: string): Promise<void> => {

        let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`, });

        if (!params.orgSeasonId) {
            throw Error('OrgSeason ID is missing');
        }

        if (!params.memberId) {
            throw Error('Member ID is missing');
        }

        if (member?.id) {
            setIsLoadingInfo(true);
            const identifierCommand = { title:title };
            await updateMemberTitle(accessToken, member!.id!, identifierCommand)

            getMember(accessToken, params.memberId)
                .then((member) => {
                    setMember(member);
                })
                .finally(() => setIsLoadingInfo(false));
        }
    }

    const handleSubmitMetaData = async (metaData: MemberMetaData): Promise<void> => {
        if (member?.id) {

            let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`, });

            setIsLoadingCardData(true);
            await updateMemberMetaData(accessToken, member!.id!, metaData);
            setIsLoadingCardData(false);

            navigate(`/orgseason/${member.orgSeason.id}/team/${member.team?.id}`);
        }
    }

    async function deleteMemberHandler() {

        let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}` });
        setIsLoadingDeleteMember(true);
        await deleteMember(accessToken, member!.id!);
        setIsLoadingDeleteMember(false);
        navigate(`/orgseason/${member!.orgSeason.id}/team/${member!.team?.id}`);
    };

    const generateCardsHandler = async () => {

        let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}` });

        setIsLoadingGenerateCards(true);

        const cardResponse = await generateMemberTradingCards(accessToken, member!.id, '9UP'); //todo: server should generate both
        downloadBase64AsFile(cardResponse.data, cardResponse.name);

        setIsLoadingGenerateCards(false);
    };

    async function download9UPCardsHandler() {
        downloadCardsHandler('9UP');
    };

    async function download1UPCardsHandler() {
        downloadCardsHandler('1UP');
    };

    const downloadCardsHandler = async (layout: string) => {

        setIsLoadingDownloadCards(true);

        let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}` });
        const cardResponse = await viewMemberTradingCards(accessToken, member!.id, layout);
        downloadBase64AsFile(cardResponse.data, cardResponse.name);

        setIsLoadingDownloadCards(false);
    };

    const generateBinderSheetHandler = async () => {

        setIsLoadingDownloadCards(true);

        let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}` });
        const binderSheetResponse = await generateBinderSheet(accessToken, member!.id);
        downloadBase64AsFile(binderSheetResponse.data, binderSheetResponse.name);

        setIsLoadingDownloadCards(false);
    };

    const menuItems = [
        {title:'Generate Cards', onClick: generateCardsHandler},
        {title:'Generate Binder Sheet', onClick: generateBinderSheetHandler},
        { title: 'Download Cards', onClick: download9UPCardsHandler },
        { title: 'Download Cards - 1UP', onClick: download1UPCardsHandler },
    ];

    const generalButtons = () => {
        return (
            <Box>
                <Grid item container spacing={2}>

                
                    <Grid item>
                        {isLoadingDeleteMember && <CircularProgress />}

                        {!isLoadingDeleteMember &&
                            <Button color="error"
                                variant="contained"
                                onClick={e => deleteMemberHandler()} >
                                Delete Member
                            </Button>
                        }
                    </Grid>
                </Grid>
            </Box>
        );
    }



    const mainContent = () => {

        return (
            <MemberContext.Provider value={{ member, refreshMember, updateContactInfo }}>
                <Box>
                    <h1>{member?.firstName}  {member?.lastName} - ({member?.role})</h1>
                    <h2>{member?.team?.name}</h2>

                    {generalButtons()}
                    <br />
                    {memberProfileImage && <img src={memberProfileImage} height={200} alt='profile' />}
                    <Box>
                        <UploadImageButton id="uploadProfile" onChange={handleChangeProfile} maxWidth={500} maxHeight={500} aspectRatio={1} />
                    </Box>

                    <br />

                    {<MemberTabs member={member}
                        actionImage={memberActionImage}
                        onSubmitMemberName={handleSubmitMemberName}
                        onSubmitJerseyNumber={handleSubmitJerseyNumber}
                        onSubmitPosition={handleSubmitPostion}
                        onSubmitTitle={handleSubmitTitle}
                        onSubmitMetaData={handleSubmitMetaData}
                        onSubmitChangeAction={handleChangeAction}
                        onUpdate={handlePageRefresh}
                        isLoadingCardData={isLoadingCardData}
                        isLoadingDocument={isLoadingDocument}
                        isLoadingAction={isLoadingAction}
                        isLoadingInfo={isLoadingInfo} />}

                </Box>
            </MemberContext.Provider>
        );
    }

    return (<Box>
        <ErrorHeader errorMessage={errorMessage} />
        <Grid container>
            <Grid item>{member && <BasicBackButton labelText='' navigateUrl={`/orgseason/${member.orgSeason.id}/team/${member.team?.id}`} />}</Grid>
            <Grid item><BasicMenu items={menuItems} /></Grid>
        </Grid>

        {isLoading && <CircularProgress />}
        {member && mainContent()}
    </Box>
    );

}