import { MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DropDownItem } from "../models/dropDownItemViewModel";

export interface BasicDropDownProps {
    id: string,
    required?: boolean,
    fullWidth?: boolean,
    label: string,
    value: any,
    width: number,
    items?: DropDownItem[]
    onChange: (React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>)
}

export function BasicDropDown(props: BasicDropDownProps) {

    const [helperText, setHelperText] = useState<string | undefined>(undefined);
    const [isError, setIsError] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>('');

    useEffect(() => {
        const reqLabel = props.required ? 'req ' : '';
        setHelperText(`${reqLabel}`);
    }, []);


    useEffect(() => {
    }, [props.items]);

    return (
        <>
            <TextField select
                id={props.id}
                label={props.label}
                variant="outlined"
                size="small"
                inputProps={{ style: { backgroundColor: "white" } }}
                value={props.value}
                style={{ width: props.width, marginRight: 10 }}
                helperText={isError ? errorText : ''}
                onChange={props.onChange}>
                {props.items && props.items.map(option => (<MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>))}
            </TextField>
        </>
    )
}