import { AppBar, Toolbar, Typography } from '@mui/material';

export function Footer() {
    return (
        <AppBar position="sticky" elevation={0} component="footer" color="primary">
            <Toolbar style={{ justifyContent: "center" }}>
                <Typography variant="caption">©2022 ColbrenSolutions</Typography>
            </Toolbar>
        </AppBar>
    );
};

