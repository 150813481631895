
export function convertBlobToBase64Url(file: Blob){
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
            resolve(fileReader.result);
        }
        fileReader.onerror = (error) => {
            reject(error);
        }
    })
}

export function downloadBase64AsFile(data: string, filename: string){
    const downloadLink = document.createElement("a");
    downloadLink.href = data;
    downloadLink.download = filename;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}

export function  downloadStringAsFile(data: string, filename: string){
    const downloadLink = document.createElement("a");
    const file = new Blob([data], { type: 'text/plain' });
    downloadLink.href = URL.createObjectURL(file);;
    downloadLink.download = filename;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}