import React, { useEffect, useState } from "react";
import { Grid, Box } from '@mui/material';
import { BasicCard } from "../../shared/basicCard";
import { useApi } from "../../hooks/useApi";
import { BasicErrorLabel } from "../../shared/basicErrorLabel";
import { changeDivisionApi } from "../../api/orgSeasonApi";
import { BasicTextBox } from "../../shared/basicTextBox";
import { MiniGrid, RowItem } from "../../shared/miniGrid";
import { useOrgSeasonContext } from "../../context/orgSeasonContext";
import { BasicButton } from "../../shared/basicButton";

export interface ManagePanelProps { }

export function ManageDivisionsPanel() {

    const [divisionRows, setDivisionRows] = useState<RowItem[] | undefined>(undefined);
    const [newDivisionName, setNewDivisionName] = useState<string>('');
    const [newDivisionNameError, setNewDivisionNameError] = useState<string>('');

    const updateRequest = useApi(changeDivisionApi);
    const { orgSeason, refreshOrgSeason } = useOrgSeasonContext();

    useEffect(() => {
        if (orgSeason) {
            mapDivisionsToRows(orgSeason.divisions);
        }

    }, [orgSeason]);


    async function mapDivisionsToRows(responseDivisions: string[]) {
        if (responseDivisions && responseDivisions.length > 0) {
            const tempRows = responseDivisions.map((division) => ({
                id: division,
                items: [{ name: 'name', value: division }]
            }));

            setDivisionRows(tempRows);
        }
    }

    function handleNewDivisionName(e: React.ChangeEvent<HTMLInputElement>) {
        setNewDivisionName(e.target.value);
    }

    async function handleRemoveDivision(id: string): Promise<boolean> {
        if (orgSeason) {
            const addDivisionCommand = { name: id, action: 'remove' };
            await updateRequest.request(orgSeason?.id, addDivisionCommand);
            refreshOrgSeason();
        }
        return true;
    }

    async function addDivision() {

        if (!newDivisionName) {
            setNewDivisionNameError('required');
            return;
        }

        const addDivisionCommand = { name: newDivisionName, action: 'add' };
        await updateRequest.request(orgSeason?.id, addDivisionCommand);
        refreshOrgSeason();
    }

    return (<>
        <Grid container spacing={3} alignItems="center">
            <Grid item><h3>Manage Divisions</h3></Grid>
        </Grid>
        <BasicCard>

            {orgSeason &&
                <Box>
                    <Grid container spacing={1} >
                        <Grid>
                            <BasicTextBox id='txtDivisionName'
                                label='Division Name*'
                                errorMessage={newDivisionNameError}
                                maxLength={20}
                                width={250}
                                value={newDivisionName}
                                onChange={handleNewDivisionName} />
                        </Grid>

                        <Grid>
                            <BasicButton onClick={addDivision} labelText="Add" />
                        </Grid>

                    </Grid>
                    {updateRequest.error && <BasicErrorLabel errors={[updateRequest.error]} />}
                    {divisionRows && <MiniGrid rows={divisionRows} onDelete={handleRemoveDivision} />}
                </Box>
            }
        </BasicCard>
    </>
    );
}