
import { Grid, Button, Box } from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';


export function HomePage() {

    const { isAuthenticated } = useAuth0();

    const navigate = useNavigate();

    function viewOrgHandler() {
        navigate(`/orgs`);
    };

    function orderFormPageHandler() {
        navigate(`/order`);
    };


    function renderHomePage() {
        return (
            <Grid container direction='column' spacing={4}>

                <Grid item>
                    <h1>Features</h1>
                </Grid>

                <Grid item container spacing={2}>

                    <Grid item xs={12} md={4}>
                        <img src='/images/sampleTradingCard.png' height='175' alt='tradingCard' /><br />

                    </Grid>

                    <Grid item xs={12} md={8}>
                        <h3>The Mission</h3>
                        <p>Deliver an improved youth sports experience by creating tools
                            and processes to generate rosters, playing cards, manage athlete documents, attendance,
                            volunteer hours and more.
                        </p>
                    </Grid>
                </Grid>

                <Grid item container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <img src='/images/sample9Up.png' height='175' alt='tradingCard' /><br />

                    </Grid>

                    <Grid item xs={12} md={8}>
                        <h3>The Cards</h3>
                        <p>Every leader and athlete gets a keepsake deck of player cards containing a
                            card of every athlete and leader on their team at no charge to the athlete.
                        </p>
                    </Grid>
                </Grid>

                <Grid item container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <img src='/images/sampleTeamRoster.png' height='175' alt='roster' /><br />

                    </Grid>

                    <Grid item xs={12} md={8}>
                        <h3>The Rosters</h3>
                        <p>Generate rosters for the parents and fans to put a name and face to the jersey number.
                            The back side of the rosters can be used for sponsorhip and advertising which will help
                            pay for cards and rosters andvpossibly bring in additional funding.
                        </p>
                    </Grid>
                </Grid>

                <Grid item container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <img src='/images/profileScreen.png' height='175' alt='roster' /><br />

                    </Grid>

                    <Grid item xs={12} md={8}>
                        <h3>Profile Photos</h3>
                        <p>ColbrenSports provides an easy way to capture and associate a profile photo for each athlete that is cropped and resized.
                        </p>
                    </Grid>
                </Grid>

                <Grid item container spacing={2}>

                    <Grid item xs={12} md={4}>
                        <img src='/images/sampleAthletePage.png' height='150' alt='tradingCard' /><br />

                    </Grid>

                    <Grid item xs={12} md={8}>
                        <h3>How it Works</h3>
                        <ul>
                            <li>Create seasons, teams, sponors for your organization</li>
                            <br />
                            <li>Generate roster by importing or manually adding athletes and leaders</li>
                            <br />
                            <li>Use ColbrenSports app to capture and associate profile photos to athletes and leaders </li>
                            <br />
                            <li>Enter player card info for each athlete and leader </li>
                            <br />
                            <li>Upload an 'action' photo for each athlete in their gameday uniform </li>
                            <br />
                            <li>Generate rosters, cards, binder labels and more via the ColbrenSports app </li>
                        </ul>
                    </Grid>
                </Grid>



            </Grid>

        );
    }

    return (
        <Box>

            <Box sx={{ margin: "10px" }} >
                <Button color="primary"
                    variant="contained"
                    onClick={e => viewOrgHandler()} >
                    View Orgs
                </Button>
            </Box>

            {renderHomePage()}

            {/* <Grid item>
                    <Button color="primary"
                        variant="contained"
                        onClick={e => orderFormPageHandler()} >
                        Order Products
                    </Button> 
            </Grid>*/}

            <Box>
                For more info email: < a href='mailto:info@colbrensports.com'>info@colbrensports.com</a>
            </Box>

        </Box>

    );
}