import { Card } from "@mui/material";

export interface BasicCardProps {
    children: any
}

export function BasicCard(props: BasicCardProps) {

    return (
        <Card style={{ maxWidth: 900, margin: 5, padding: 20, border: '1px solid #909090', borderRadius: 15, backgroundColor: '#f5f5f5', textDecoration: 'none' }}>
            {props.children}
        </Card>
    );

}