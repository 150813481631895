import React from 'react';
import Webcam from "react-webcam";
import { Grid, Button, Box } from '@mui/material';

export interface WebCamCaptureProps {
    onCapture: (image: string) => void,
    onCancel?: () => void,
    width?: number,
    height?: number,
    facingMode?: string
}

export function WebCamCapture(props: WebCamCaptureProps) {

    const webcamRef = React.useRef<Webcam>(null);

    const videoConstraints = {
        width: props.width ?? 500,
        height: props.height ?? 500,
        facingMode: props.facingMode ?? 'environment'
    };

    const capture = React.useCallback(() => {
        if (webcamRef !== null && webcamRef.current !== null) {
            const snapshot = webcamRef.current.getScreenshot({ width: props.width ?? 500, height: props.height ?? 500 });
            if (snapshot) {
                props.onCapture(snapshot);
            }
        }
    }, [webcamRef, props]);

    function cancel() {
        if (props.onCancel) {
            props.onCancel();
        }
    }

    return (
        <Grid container spacing={1} direction='column'>

            <Grid item>
                <Box sx={{
                    border: '1px solid black',
                    height: 152,
                    width: 152,
                    padding: 1
                }}>
                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        height='150px'
                        screenshotFormat="image/jpeg"
                        screenshotQuality={1}
                        videoConstraints={videoConstraints}
                    />
                </Box>
            </Grid>

            <Grid container item spacing={1} >

                <Grid item>
                    <Button color="primary"
                        variant="contained"
                        onClick={e => capture()}>Capture</Button>
                </Grid>

                <Grid item>
                    <Button color="primary"
                        variant="contained"
                        onClick={e => cancel()}>Cancel</Button>
                </Grid>

            </Grid>

        </Grid>

    );

}