import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { addMemberDocumentApi, getMemberDocumentApi, deleteMemberDocumentApi } from '../../api/memberApi';
import { Member, MemberDocument } from '../../api/models/member';
import { useApi } from '../../hooks/useApi';
import { DocumentGrid } from '../../shared/documentGrid';
import { RowItem } from '../../shared/miniGrid';
import { UploadFileButton } from '../../shared/uploadFileButton';
import { downloadBase64AsFile } from '../../utils/binaryHelper';

export interface MemberTabProps {
    member: Member | null
}

export default function MemberDocumentTab(props: MemberTabProps) {

    const [documentRows, setDocumentRows] = useState<RowItem[] | undefined>(undefined);

    const addRequest = useApi(addMemberDocumentApi);
    const getRequest = useApi(getMemberDocumentApi);
    const deleteRequest = useApi(deleteMemberDocumentApi);

    useEffect(() => {
        initPage();
    }, []);

    useEffect(() => {
        if (getRequest.data) {
            downloadBase64AsFile(getRequest.data.documentData, getRequest.data.name);
        }

    }, [getRequest.data]);

    async function initPage() {
        if (props.member && props.member.documents) {
            mapDocumentsToRows(props.member.documents);
        }
    }


    async function mapDocumentsToRows(responseDocuments: MemberDocument[]) {
        if (responseDocuments && responseDocuments.length > 0) {
            const tempRows = responseDocuments.map((doc) => ({
                id: doc.id,
                items: [{ name: 'name', value: doc.name }, { name: 'type', value: doc.documentType }]
            }));

            setDocumentRows(tempRows);
        }
    }


    async function addDocument(selectedFile: string, fileName: string, mimeType: string, size: number, documentType?: string) {

        const docType = documentType ?? 'Other';

        const documentCommand = { name: fileName, documentType: docType, documentFormat: mimeType, documentData: selectedFile };
        await addRequest.request(props.member?.id, documentCommand);
    }

    async function handleDeleteDocument(documentId: string): Promise<boolean> {
        await deleteRequest.request(props.member!.id, documentId);
        return true;
    }

    async function handleSelectDocument(documentId: string): Promise<boolean> {
        await getRequest.request(props.member!.id, documentId);
        return true;
    }

    return (
        <Grid container spacing={1} style={{ height: '400px' }}>
            <Grid item xs={12} >
                <UploadFileButton selectDocType onChange={addDocument} id={'filueUpload'} /><br />
                {documentRows && <DocumentGrid rows={documentRows} onSelect={handleSelectDocument} onDelete={handleDeleteDocument} />}
            </Grid>
        </Grid>
    )
}