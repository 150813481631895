import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { IconButton, Menu, MenuItem, Avatar } from '@mui/material';
import { resetPasswordEmail } from '../api/auth0Api';

export function LogoutButton(){
  const { user, logout } = useAuth0();
  const [profileMenu, setProfileMenu] = React.useState<Element | null>(null);

  async function handlePasswordReset(){

    console.log(`send password reset for ${user?.email}`);

    if(user?.email) {
      const result = await resetPasswordEmail(user?.email);
      console.log(result);
    }
  }

  return (
    <div>
      <IconButton onClick={(e) => setProfileMenu(e.currentTarget as Element)}>
        <Avatar
          src={user?.picture}
          style={{
            margin: '10px',
            width: '60px',
            height: '60px',
          }}
        />
      </IconButton>

      <Menu
        id='profile-menu'
        open={Boolean(profileMenu)}
        anchorEl={profileMenu}
        onClose={() => setProfileMenu(null)}
        disableAutoFocusItem
      >
        <MenuItem onClick={() => logout({ returnTo: window.location.origin })}>
          LogOut
        </MenuItem>
        <MenuItem onClick={() => handlePasswordReset()}>
          Reset Password (email)
        </MenuItem>
      </Menu>
    </div>
  );
};
