import {LoginButton} from './loginButton';
import {LogoutButton} from './logoutButton';
import { useAuth0 } from '@auth0/auth0-react'

export function AuthenticationButton(){

    const { isAuthenticated } = useAuth0();

    return (<div>
        {isAuthenticated ? <LogoutButton /> : <LoginButton />}
    </div>);
};