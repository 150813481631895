import React, { useEffect, useState } from "react";
import { Grid, MenuItem, TextField, Box, Button, IconButton } from '@mui/material';
import { getSingleOrgSeasonApi } from '../../api/orgSeasonApi';
import { useNavigate, useParams } from 'react-router-dom';
import { OrgSeason } from "../../api/models/orgSeason";
import { useAuth0 } from "@auth0/auth0-react";
import { WebCamCapture } from "../../shared/webCamCapture";
import { CircularProgress } from '@mui/material';
import { Team } from "../../api/models/team";
import { ErrorHeader } from "../../shared/errorHeader";
import { getAccess } from "../../api/userApi";
import { PersonView } from "../../models/personView";
import { getMemberProfileImage, updateMemberProfileImage } from "../../api/memberApi";

import { BasicBackButton } from "../../shared/basicBackButton";
import { groupBy } from "../../utils/common";

export const ProfileCapturePage: React.FC = (): JSX.Element => {

    const [orgSeason, setOrgSeason] = useState<OrgSeason | undefined>(undefined);
    const [divisions, setDivisions] = useState<Map<string, Team[]> | undefined>(undefined);
    const [selectedDivision, setSelectedDivision] = useState<string>('');
    const [selectedTeam, setSelectedTeam] = useState<Team | undefined>(undefined);
    const [filteredPersonList, setFilteredPersonList] = useState<PersonView[]>([]);
    const [selectedPerson, setSelectedPerson] = React.useState<PersonView | undefined>(undefined);

    const [image, setImage] = useState<string | undefined>(undefined);
    const [pageState, setPageState] = useState<string>('reset');

    const [isLoading, setIsLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [accessLevel, setAccessLevel] = useState<string | undefined>(undefined);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    const [selectedImage, setSelectedImage] = useState<string>('');

    const params = useParams();
    const navigate = useNavigate();
    const { isAuthenticated, user,  getAccessTokenSilently } = useAuth0();

    useEffect(() => {

        const initPage = async () => {
            try {
                setIsLoading(true);
                setErrorMessage('');

                if (!params.orgSeasonId) {
                    throw Error('OrgSeason Id is missing');
                }

                let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`, });

                const access = await getAccess(accessToken, params.orgSeasonId, "orgSeason");
                setAccessLevel(access);
                setIsAdmin(access === "admin" || access === "superadmin");

                if (!access) {
                    throw Error('You dont have permission to access this data');
                }

                const orgSeason = await getSingleOrgSeasonApi(accessToken, params.orgSeasonId);
                console.log(orgSeason);
                setOrgSeason(orgSeason);

                if (!orgSeason.divisions || orgSeason.divisions.length < 1) {
                    throw Error('There are no divisions for this org season');
                }




                const teamList = orgSeason.teams;
                const divisionList = groupBy<string, Team>(teamList, team => team.division);
                setDivisions(divisionList);

                setIsLoading(false);

            }
            catch (error: any) {
                if (error.response?.data) {
                    setErrorMessage(error.response.data.message);
                }
                else {
                    setErrorMessage(error.message);
                }
                setIsLoading(false);
            }
        }

        initPage();

    }, [getAccessTokenSilently]);


    const selectDivision = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedDivision(event.target.value);

        setImage(undefined);
        setPageState('reset');
        setSelectedTeam(undefined);
        setFilteredPersonList([]);
    };

    const selectTeam = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedTeamId = event.target.value;
        const selectedTeam = divisions?.get(selectedDivision)?.find(team => team.id === selectedTeamId);
        if (selectedTeam) {
            setSelectedTeam(selectedTeam);
            const personList = generatePersonList(selectedTeam);
            setFilteredPersonList(personList);

            setImage(undefined);
            setPageState('reset');
        }
        else {
            setErrorMessage('could not find the selected team!');
        }
    };

    function generatePersonList(team: Team): PersonView[] {
        let personList: PersonView[] = [];

        if (team.members) {
            const sortedMembers = team.members.sort((a, b) => a.lastName.localeCompare(b.lastName));
            const memberPersons = sortedMembers.map((member) => ({ id: member.id, isLeader: false, displayName: `${member.lastName}, ${member.firstName}`, profileLink: member.profileLink }));
            personList = personList.concat(memberPersons);
        }

        if (team.leaders) {
            const sortedLeaders = team.leaders.sort((a, b) => a.lastName.localeCompare(b.lastName));
            const leaderPersons = sortedLeaders.map((leader) => ({ id: leader.id, isLeader: true, displayName: `${leader.lastName}, ${leader.firstName} - ${leader.title}`, profileLink: leader.profileLink }));
            personList = personList.concat(leaderPersons);
        }

        return personList;
    }

    async function selectPerson(event: React.ChangeEvent<HTMLInputElement>) {
        const selectedPersonId = event.target.value;
        const selectedPerson = filteredPersonList.find(person => person.id === selectedPersonId);

        let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`, });

        if (selectedPerson?.isLeader) {

            const profileImage = await getMemberProfileImage(accessToken, selectedPerson.id);
            setSelectedImage(profileImage.image);
        }
        else {
            const profileImage = await getMemberProfileImage(accessToken, selectedPerson!.id);
            setSelectedImage(profileImage.image);
        }

        setSelectedPerson(selectedPerson);
        setPageState('personSelected');
    };

    async function displayUpdatedMemberImage() {
        if (selectedPerson) {
            let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`, });
            //const updatedPerson = await getMember(accessToken, selectedPerson?.id);
            //setSelectedPerson(updatedPerson);
        }

    }

    function updateImage() {
        setPageState('update');
    }

    function cancelUpdate() {
        setPageState('personSelected');
    }

    function captureImage(photo: string) {
        setImage(photo);
        setPageState('save');
    }

    function saveImage() {
        uploadImage();
    }

    async function uploadImage() {

        try {
            setPageState('saving');
            if (orgSeason && selectedPerson && image) {
                let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}` });
                const addImageCommand = { image: image };

                if (selectedPerson.isLeader) {
                    await updateMemberProfileImage(accessToken, selectedPerson.id, addImageCommand);

                    const profileImage = await getMemberProfileImage(accessToken, selectedPerson.id);
                    setSelectedImage(profileImage.image);
                    setPageState('saved');
                }
                else {
                    await updateMemberProfileImage(accessToken, selectedPerson.id, addImageCommand);

                    const profileImage = await getMemberProfileImage(accessToken, selectedPerson.id);
                    setSelectedImage(profileImage.image);
                    setPageState('saved');
                }
            }
            else {
                //todo - break this logic out into seperate messages
                setErrorMessage('Either the orgSeason wasnt found, person isnt selected, or image doesnt exist');
                setPageState('personSelected');
            }



        }
        catch (error: any) {
            console.log('Error');
            console.log(error.response);
            if (error.response) {
                setErrorMessage(error.response.data.message);
            }
            else {
                setErrorMessage(error.message);
            }
            setPageState('personSelected');
        }

    }

    function isState(state: string): boolean {
        return state === pageState;
    }


    const mainContent = () => {

        return (
            <>
                <br />
                <Grid item container spacing={2} direction='column' >

                    <Grid item >
                        <TextField select
                            fullWidth
                            label="Select "
                            size="small"
                            value={selectedDivision}
                            onChange={selectDivision}
                            helperText="Select a division">
                            {orgSeason && orgSeason.divisions.map(option => (<MenuItem key={option} value={option}>{option}</MenuItem>))}
                        </TextField>
                    </Grid>

                    <Grid item>
                        <TextField select
                            fullWidth
                            label="Select"
                            size="small"
                            value={selectedTeam?.id ?? ''}
                            onChange={selectTeam}
                            helperText="Select a team">
                            {divisions && divisions.get(selectedDivision)?.map(option => (<MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>))}
                        </TextField>
                    </Grid>

                    <Grid item>
                        <TextField select
                            fullWidth
                            label="Select"
                            size="small"
                            value={selectedPerson?.id ?? ''}
                            onChange={selectPerson}
                            helperText="Select a member">
                            {filteredPersonList.map(option => (<MenuItem key={option.id} value={option.id}>{option.displayName}</MenuItem>))}
                        </TextField>
                    </Grid>

                    <Grid item container >
                        {(isState('personSelected') || isState('saved')) && <Grid item xs>
                            <Box>
                                <Grid container spacing={1} direction='column'>
                                    {isState('personSelected') && <Grid item><label style={{ color: '#000000', fontWeight: '600' }}>Current Image</label></Grid>}
                                    {isState('saved') && <Grid item><label style={{ color: '#008000', fontWeight: '600' }}>Saved</label></Grid>}
                                    <Grid item><img src={selectedImage} height={150} alt='current profile' /></Grid>
                                    <Grid item container spacing={1}><Button color="primary"
                                        variant="contained"
                                        onClick={e => updateImage()}>Update</Button></Grid>

                                </Grid>
                            </Box>
                        </Grid>}

                        {isState('update') && <Grid item xs>
                            <Box>
                                <label style={{ color: '#000000', fontWeight: '600' }}>Capture Image</label>
                                {isAuthenticated && <WebCamCapture onCapture={captureImage} onCancel={cancelUpdate} />}
                            </Box>
                        </Grid>}

                        {isState('save') && <Grid item xs>
                            <Box>
                                <Grid container spacing={1} direction='column'>
                                    <Grid item><label style={{ color: '#000000', fontWeight: '600' }}>Save Image</label></Grid>
                                    <Grid item><img src={image} height={150} alt='profile to save' /></Grid>
                                    <Grid item container spacing={1}>
                                        <Grid item>
                                            <Button color="primary"
                                                variant="contained"
                                                onClick={e => saveImage()}>Save</Button>
                                        </Grid>

                                        <Grid item>
                                            <Button color="primary"
                                                variant="contained"
                                                onClick={e => cancelUpdate()}>Cancel</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>}

                        {isState('saving') && <Grid item xs>
                            <Box>
                                <CircularProgress />
                            </Box>
                        </Grid>}


                    </Grid>

                    <Grid item xs>

                    </Grid>

                </Grid>
            </>
        );

    }

    return (<Box>
        <ErrorHeader errorMessage={errorMessage} />
        {orgSeason && <BasicBackButton labelText='' navigateUrl={`/orgseason/${orgSeason.id}`} />}
        {isLoading && <CircularProgress />}
        {orgSeason && mainContent()}
    </Box>
    );

}