import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './app';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-92aqgcde.auth0.com"
      clientId={process.env.REACT_APP_AUTH0_CLIENTID ?? ''}
      redirectUri={window.location.origin}
      audience="https://dev-92aqgcde.auth0.com/api/v2/"
      cacheLocation='localstorage'
      useRefreshTokens={true}
      scope="read:current_user update:current_user_metadata">
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
