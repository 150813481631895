import axios from 'axios';

export async function resetPasswordEmail(email:string): Promise<string>{
  const baseUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}`;
  const endpoint = `/dbconnections/change_password`;
  const data = {
    email: email,
    connection:'Username-Password-Authentication'
  };
  const response = await axios.post(baseUrl + endpoint, data, { timeout: 60000 });
  console.log('password reset response',response.data);
  return response.data as string;
}
