import { Box, Typography } from '@mui/material';

export interface ErrorHeaderProps {
    errorMessage?: string,
}

export function ErrorHeader(props: ErrorHeaderProps) {

    return (
        <>
            {props.errorMessage &&
                <Box sx={{ color: 'black', backgroundColor: '#fcffa4', height: '30px', paddingLeft: '10px' }}>
                    <Typography>{props.errorMessage}</Typography>
                </Box>
            }

        </>
    );
}