import React, { useEffect, useState } from "react";
import { Grid, CircularProgress, Box } from '@mui/material';
import { getSingleOrgSeasonApi } from '../../api/orgSeasonApi';
import { useParams } from 'react-router-dom';

import { ErrorHeader } from "../../shared/errorHeader";
import { getAccess } from "../../api/userApi";
import { OrgHeader } from "../../shared/orgHeader";
import { OrgSeason } from "../../api/models/orgSeason";
import { ManageLogoPanel } from "./manageLogoPanel";
import { ManageNamePanel } from "./manageNamePanel";
import { ManageDivisionsPanel } from "./manageDivisionsPanel";
import { ManageTeamsPanel } from "./manageTeamsPanel";
import { ManageSponsorsPanel } from "./manageSponsorsPanel";
import { ManageImportsPanel } from "./manageImportsPanel";
import { ManageExportsPanel } from "./manageExportsPanel";
import { ManageCopyMemberDataPanel } from "./manageCopyMemberDataPanel";
import { useApi } from "../../hooks/useApi";
import { OrgSeasonContext } from "../../context/orgSeasonContext";
import { ManageGetCardDataPanel } from "./manageGetCardDataPanel";
import { BasicBackButton } from "../../shared/basicBackButton";


export function OrgSeasonAdminPage() {

    const [orgSeason, setOrgSeason] = useState<OrgSeason | undefined>(undefined);

    const params = useParams();
    const getUserRequest = useApi(getAccess);
    const getOrgRequest = useApi(getSingleOrgSeasonApi);

    //trigger on page load
    useEffect(() => {
        updatePageData();
    }, []);

    //triggers when we get the users role to determine if we can get orgSeason data 
    useEffect(() => {
        if (getUserRequest.data) {
            fetchOrgData(getUserRequest.data);
        }

    }, [getUserRequest.data]);

    //triggers when orgSeason is updated
    useEffect(() => {
        if (getOrgRequest.data) {
            setOrgSeason(getOrgRequest.data);
        }

    }, [getOrgRequest.data]);

    //verify param for orgSeason id exist and request user role for this org
    async function updatePageData() {
        if (!params.orgSeasonId) {
            throw Error('Organization Id is missing');
        }

        await getUserRequest.request(params.orgSeasonId, "orgSeason");
    }

    //verify the user has permission to access data and retrieve org data - server will also verify
    async function fetchOrgData(userRole: string): Promise<void> {
        const isAdmin = userRole === "admin" || userRole === "superadmin";

        if (!isAdmin) {
            throw Error('You dont have permission to access this data');
        }

        await getOrgRequest.request(params.orgSeasonId);
    }

    function refreshOrgSeason(): void {
        getOrgRequest.request(params.orgSeasonId);
    }

    function mainContent() {

        return (
            <OrgSeasonContext.Provider value={{ orgSeason, refreshOrgSeason }}>

                <Box sx={{ width: 600 }}>
                    <Grid item container spacing={1} direction='column' justifyContent="flex-start">
                        <Grid item>
                            <Grid item container spacing={2} justifyContent="flex-start" alignItems="center" >
                                <Grid item><h1>{orgSeason!.name} Admin Page</h1></Grid>
                            </Grid>
                        </Grid>

                        <Grid item container spacing={2} direction='column'>

                            <Grid item>
                                <ManageNamePanel />
                            </Grid>

                            <Grid item>
                                <ManageLogoPanel />
                            </Grid>

                            <Grid item>
                                <ManageDivisionsPanel />
                            </Grid>

                            <Grid item>
                                <ManageTeamsPanel />
                            </Grid>

                            <Grid item>
                                <ManageSponsorsPanel />
                            </Grid>

                            
                            {/* <Grid item>
                                <ManageGetCardDataPanel orgSeasonId={orgSeason!.id} />
                            </Grid> */}

                            <Grid item>
                                <ManageImportsPanel orgSeasonId={orgSeason!.id} />
                            </Grid>

                            <Grid item>
                                <ManageExportsPanel orgSeasonId={orgSeason!.id} />
                            </Grid>

                        </Grid>

                    </Grid>
                </Box>
            </OrgSeasonContext.Provider>
        );
    }

    return (<Box>
        <Box>{orgSeason && <BasicBackButton labelText='' navigateUrl={`/orgseason/${orgSeason.id}`} />}</Box>
        <ErrorHeader errorMessage={getUserRequest.error} />
        {(getUserRequest.loading || getOrgRequest.loading) && <CircularProgress />}
        {orgSeason && mainContent()}
    </Box>
    );

}