import React, { useEffect, useState } from "react";
import { Grid, Box, CircularProgress, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { BasicCard } from "../../shared/basicCard";
import { useApi } from "../../hooks/useApi";
import { BasicErrorLabel } from "../../shared/basicErrorLabel";

import { getSingleOrganizationApi } from '../../api/organizationApi';
import { addOrgSeasonApi, deleteSingleOrgSeasonApi } from "../../api/orgSeasonApi";
import { MiniGrid, RowItem } from "../../shared/miniGrid";
import { OrgSeason } from "../../api/models/orgSeason";
import { BasicTextBox } from "../../shared/basicTextBox";

export interface ManageOrgSeasonsPanelProps {
    organizationId: string
}

export function ManageOrgSeasonsPanel(props: ManageOrgSeasonsPanelProps) {

    const [orgSeasonRows, setOrgSeasonRows] = useState<RowItem[] | undefined>(undefined);

    const [newOrgSeasonName, setNewOrgSeasonName] = useState<string>('');
    const [orgSeasonNameError, setOrgSeasonNameError] = useState<string>('');

    const getOrganizationRequest = useApi(getSingleOrganizationApi);
    const addOrgSeasonRequest = useApi(addOrgSeasonApi);
    const deleteOrgSeasonRequest = useApi(deleteSingleOrgSeasonApi);

    const navigate = useNavigate();

    useEffect(() => {
        initPage();
    }, []);

    useEffect(() => {
        if (getOrganizationRequest.data) {
            mapOrgSeasonsToRows(getOrganizationRequest.data.orgSeasons);
        }

    }, [getOrganizationRequest.data]);

    async function initPage() {
        setOrgSeasonNameError('');
        setNewOrgSeasonName('');
        await getOrganizationRequest.request(props.organizationId);
    }

    async function mapOrgSeasonsToRows(responseOrgSeasons: OrgSeason[]) {
        if (responseOrgSeasons && responseOrgSeasons.length > 0) {
            const tempOrgSeasonRows = responseOrgSeasons.map((orgSeason) => ({
                id: orgSeason.id,
                items: [{ name: 'name', value: orgSeason.name }]
            }));

            setOrgSeasonRows(tempOrgSeasonRows);
        }
    }

    function handleNewOrgSeasonName(e: React.ChangeEvent<HTMLInputElement>) {
        setNewOrgSeasonName(e.target.value);
    }

    async function handleAddOrgSeason() {

        if (!newOrgSeasonName) {
            setOrgSeasonNameError('required');
            return;
        }

        const createOrgSeasonCommand = {
            organizationId: props.organizationId,
            name: newOrgSeasonName
        };

        await addOrgSeasonRequest.request(createOrgSeasonCommand);
        await initPage();
    }

    async function navigateToEditOrg(id: string): Promise<boolean> {
        navigate(`/orgseason/${id}/admin`);
        return true;
    }

    async function handleDeleteOrgSeason(id: string): Promise<boolean> {
        await deleteOrgSeasonRequest.request(id)
        await initPage();
        return true;
    }

    return (<>
        <Grid container spacing={3} alignItems="center">
            <Grid item><h3>Manage Org Seasons</h3></Grid>
        </Grid>
        <BasicCard>

            {getOrganizationRequest.loading && <CircularProgress />}

            {!getOrganizationRequest.loading &&
                <Box>
                    {addOrgSeasonRequest.loading && <CircularProgress />}
                    {deleteOrgSeasonRequest.loading && <CircularProgress />}
                    {!addOrgSeasonRequest.loading && !deleteOrgSeasonRequest.loading && <Grid container spacing={1} >
                        <Grid>
                            <BasicTextBox id='txtOrgSeason'
                                label='Org Season Name*'
                                errorMessage={orgSeasonNameError}
                                maxLength={25}
                                width={250}
                                value={newOrgSeasonName}
                                onChange={handleNewOrgSeasonName} />
                        </Grid>

                        <Grid>
                            <Button color="primary" variant="contained" onClick={handleAddOrgSeason} component="span"> ADD
                            </Button>
                        </Grid>

                    </Grid>}

                    {getOrganizationRequest.error && <BasicErrorLabel errors={[getOrganizationRequest.error]} />}
                    {addOrgSeasonRequest.error && <BasicErrorLabel errors={[addOrgSeasonRequest.error]} />}
                    {deleteOrgSeasonRequest.error && <BasicErrorLabel errors={[deleteOrgSeasonRequest.error]} />}
                    {orgSeasonRows && <MiniGrid rows={orgSeasonRows} onEdit={navigateToEditOrg} onDelete={handleDeleteOrgSeason} />}
                </Box>
            }
        </BasicCard>
    </>
    );
}